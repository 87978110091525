import req from './utils/req'

/**
 * Get
 */
export const get = (id) => req({

  method: 'GET',
  route: `/calendario/${id}`,

})

/**
 * List
 */
export const list = (year, month) => req({

  method: 'GET',
  route: `/calendario/ano/${year}/mes/${month}`,

})

/**
 * Add
 */
export const add = (data) => req({

  method: 'POST',
  route: `/calendario`,
  data,

})

/**
 * Remove
 */
export const remove = (id) => req({

  method: 'DELETE',
  route: `/calendario/${id}`,

})


/**
 * Reserva to agendamento
 */
export const reservaToAgendamento = (id, data) => req({

  method: 'POST',
  route: `/calendario/reserva/${id}`,
  data,

})

/**
 * Search
 */
export const search = (searchParams) => req({

  method: 'GET',
  route: `/calendario${searchParams}`,

})

/**
 * Update
 */
export const update = (id, data) => req({

  method: 'PUT',
  route: `/calendario/${id}`,
  data,

})
