/**
 * Obter data atual no formatd DD/MM/YYYY
 * @example
 * getDate() // Supondo que hoje seja 01/01/2001
 * //=> '2001-01-01'
 */
export default function getDate() {
  const date = (new Date())
  const dd = (date.getDate()).toString().padStart(2, '0')
  const mm = (date.getMonth() + 1).toString().padStart(2, '0')
  const yyyy = date.getFullYear()

  return `${yyyy}-${mm}-${dd}`
}