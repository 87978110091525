import React from 'react'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import noop from '../../../utils/noop'

import Header from '../../../components/Header'

const TITLE = 'Recebimento'

export default ({ goBack = noop }) => {
  const startIcons = [
    {
      Icon: ArrowBackIcon,
      onClick: goBack
    }
  ]

  return (
    <Header
      title={TITLE}
      startActions={startIcons}
    />
  )
}