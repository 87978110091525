import req from './utils/req'

/**
 * Auth
 */
export const auth = (login, password) => req({

  method: 'POST',
  route: `/login`,
  data: {
    login,
    password
  },

})

/**
 * Get
 */
export const get = () => req({

  method: 'GET',
  route: `/usuario/info`,

})

/**
 * List
 */
export const list = () => req({

  method: 'GET',
  route: `/usuario`,

})

/**
 * List "corretor"
 */

export const listCorretor = () => req({

  method: 'GET',
  route: `/corretor`,

})