import React, { useState } from 'react'

import Header from './header'

import Dialog from '../../../components/Dialog'
import Form from '../../../components/Form'

import * as rqApartamento from '../../../api/apartamento'
import * as rqCadastros from '../../../api/cadastros'
import * as rqCliente from '../../../api/cliente'

import checkData from '../../../utils/check-form-data'
import getDate from '../../../utils/get-date'
import noop from '../../../utils/noop'

export default (props) => {
  const goBack = () => props.history.push('/')
  
  const [ apartmentList, setApartmentList ] = useState([])
  const [ locatorList, setLocatorList ] = useState([])
  const [ statusList, setStatusList ] = useState([])

  const [ dialog, createDialog ] = useState({})
  const [ loading, setLoading ] = useState(true)

  const [ formData, setFormData ] = useState({})

  const nonRequiredFields = [
    'apartmentId',
    'locatorId',
    'dateStart',
    'dateEnd',
    'statusId',
    'wasPaid',
  ]

  const setApartmentId = (apartmentId) => {
    setFormData({ ...formData, apartmentId })
  }
  const setLocatorId = (locatorId) => {
    setFormData({ ...formData, locatorId })
  }

  const handleClose = () => {
    createDialog({
      open: false
    })
  }

  const handleChangeValue = (type) => (event) => {
    const { value } = event.target

    setFormData({
      ...formData,
      [type]: value
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const continueSubmit = () => {
      const su = new URL(`${window.location.origin}/calendar/search/list`)

      if (formData.apartmentId !== null)
         su.searchParams.set('id_apartamento', formData.apartmentId)
      if (formData.locatorId !== null)
        su.searchParams.set('id_cliente', formData.locatorId)

      su.searchParams.set('start_date', formData.dateStart)
      su.searchParams.set('end_date', formData.dateEnd)
      su.searchParams.set('id_status', formData.statusId)
      su.searchParams.set('pago', formData.wasPaid ? 'Y' : 'N')

      props.history.push(`${su.pathname}${su.search}`)
    }
    const stopSumbit = () => {
      createDialog({
        open: true,
        title: 'Erro',
        message: 'Algum campo não foi preenchido.',
      })
    }

    checkData(formData, nonRequiredFields)
      .then(continueSubmit)
      .catch(stopSumbit)
  }

  if (
       loading
    && apartmentList.length > 0
    && locatorList.length > 0
    && statusList.length > 0
  ) {
    const su = new URL(window.location.href)

    const apartmentId = Number(su.searchParams.get('id_apartamento')) || null
    const locatorId   = Number(su.searchParams.get('id_cliente')) || null
    const dateStart   = su.searchParams.get('start_date') || getDate()
    const dateEnd     = su.searchParams.get('end_date') || getDate()
    const statusId    = su.searchParams.get('id_status') || 1
    const wasPaid     = su.searchParams.get('pago') === 'Y' ? 1 : 0

    setFormData({
      apartmentId,
      locatorId,
      dateStart,
      dateEnd,
      statusId,
      wasPaid
    })

    setLoading(false)
  }

  // Obter lista de apartamentos
  if (apartmentList.length === 0) {
    rqApartamento.list().then(data => {
      const list = data.map(item => ({ label: item.nome, value: item.id}))
      setApartmentList(list)
    })
  }

  // Obter lista de locadores
  if (locatorList.length === 0) {
    rqCliente.list().then(({ data }) => {
      const list = data.map(item => ({ label: item.nome, value: item.id}))
      setLocatorList(list)
    })
  }

  // Obter lista de status
  if (statusList.length === 0) {
    rqCadastros.listStatus().then(data => {
      const list = data.map(({ id, descricao }) => ({ label: descricao, value: id }))
      setStatusList(list)
    })
  }

  const formFields = [
    [
      {
        placeholder: 'Apartamento',
        type: 'autocomplete',
        suggestions: apartmentList,
        onChange: ({ value: id }) => setApartmentId(id),
        defaultValue: apartmentList.find(({ value }) => value === formData['apartmentId']),
      },
    ],
    [
      {
        placeholder: 'Locador',
        type: 'autocomplete',
        suggestions: locatorList,
        onChange: ({ value: id }) => setLocatorId(id),
        defaultValue: locatorList.find(({ value }) => value === formData['locatorId']),
      },
    ],
    [
      {
        label: 'Data inicial',
        type: 'normal',
        inputType: 'date',
        value: formData.dateStart,
        onChange: handleChangeValue('dateStart'),
      },
      {
        label: 'Data final',
        type: 'normal',
        inputType: 'date',
        value: formData.dateEnd,
        onChange: handleChangeValue('dateEnd'),
      },
    ],
    [
      {
        label: 'Status',
        type: 'select',
        options: statusList,
        value: formData.statusId,
        onChange: handleChangeValue('statusId'),
      },
    ],
    [
      {
        label: 'Foi pago?',
        type: 'select',
        options: [
          { label: 'Não', value: 0 },
          { label: 'Sim', value: 1 }
        ],
        value: formData.wasPaid,
        onChange: handleChangeValue('wasPaid'),
      },
    ],
  ]

  return (
    <>
      <Dialog
        open={dialog.open}
        title={dialog.title}
        message={dialog.message}
        onClose={handleClose}
        afterClose={() => (dialog.afterClose || noop)()}
      />

      <Form
        mainButtonLabel="Buscar"
        isLoading={loading}
        fields={formFields}
        onSubmit={handleSubmit}
        headerComponent={<Header goBack={goBack} />}
      />
    </>
  )
}