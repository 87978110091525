import React from 'react'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import Header from '../../../components/Header'

export default ({ goBack }) => (
  <Header
    title="Apartamento"
    startActions={[
      {
        Icon: ArrowBackIcon,
        onClick: goBack
      }
    ]}
  />
)