import React, { useState } from 'react'

import AddIcon from '@material-ui/icons/Add'
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation'

import Typography from '@material-ui/core/Typography'

import * as rqUsuario from '../../../api/usuario'
import * as rqTransferencia from '../../../api/transferencia'

import Header from './header'
import FloatButton from '../../../components/FloatButton'
import List from '../../../components/List'
import Loading from '../../../components/Loading'
import PageWithHeader from '../../../components/PageWithHeader'

import * as groups from '../../../groups'

export default (props) => {
  const goTo = props.history.push
  const goBack = () => goTo('/more')

  const [ list, setList ] = useState()
  const [ isProprietario, setProprietario ] = useState(null)

  if (isProprietario === null) {
    rqUsuario.get().then(data => {
      setProprietario(
           data.groups.includes(groups.ADMIN)
        || data.groups.includes(groups.PROPRIETARIO)
      )
    })
    return <Loading />
  }

  if (!list) {
    rqTransferencia.list().then((data = []) => {
      data = data.sort((a, b) => {
        if (a.descricao < b.descricao) return -1
        if (a.descricao > b.descricao) return 1
        return 0
      })

      setList([
        {
          content: data.map(({ id, descricao }) => ({
            label: descricao,
            onClick: () => goTo(`/more/transferencias/edit/${id}`),
            Icon: TransferWithinAStationIcon
          }))
        }
      ])
    })

    return <Loading />
  }

  return (
    <PageWithHeader headerComponent={<Header goBack={goBack} />}>
      {isProprietario && (
        <FloatButton onClick={() => goTo('/more/transferencias/new')}>
          <AddIcon />
        </FloatButton>
      )}
  
      {list.length > 0 ? (
        <List menuItems={list} />
      ) : (
        <Typography
          align="center"
          color="textSecondary"
          style={{
            marginTop: 20
          }}
        >
          Não há transferências.
        </Typography>
      )}
    </PageWithHeader>
  )
}