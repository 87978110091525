export default function checkFormData(formData, nonRequiredFields) {
  const isMissingFields = Object
    .entries(formData)
    .map(([ key, value ]) => {

      /**
       * Verificar se não foi definido
       */
      return !nonRequiredFields.includes(key) // Item é obrigatório
          && value !== 0                      // Item é diferente de zero
          && !value                           // Item não foi definido
    })
    .filter(Boolean)
    .length > 0

  return new Promise((resolve, reject) => {
    if (isMissingFields) {
      reject()
    } else {
      resolve()
    }
  })
}