import React from 'react'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import PeopleIcon from '@material-ui/icons/People'

import Header from '../../../components/Header'

import noop from '../../../utils/noop'

export default ({ goBack = noop, completeRegister = noop }) => (
  <Header
    title="Simplificado"
    startActions={[
      {
        Icon: ArrowBackIcon,
        onClick: goBack
      }
    ]}
    endActions={[
      {
        Icon: PeopleIcon,
        onClick: completeRegister
      }
    ]}
  />
)