import React from 'react'

import {
  InputLabel,
  Select as SelectUI
} from '@material-ui/core'

export default function Select(props) {
  const inputLabel = React.useRef(null)
  const [ labelWidth, setLabelWidth ] = React.useState(0)

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  return (
    <>
      <InputLabel ref={inputLabel}>
        {props.label}
      </InputLabel>
      <SelectUI
        {...props}
        native
        labelWidth={labelWidth}
        inputProps={{}}
      >
        {props.options.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </SelectUI>
    </>
  )
}
