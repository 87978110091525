import React, { useState } from 'react'

/*
 * Componentes locais
 */
import Header from './header'

/*
 * Componentes globais
 */
import Dialog from '../../../components/Dialog'
import Form from '../../../components/Form'

/*
 * Utilitários da API
 */
import * as rqApartamento from '../../../api/apartamento'
import * as rqCadastros from '../../../api/cadastros'
import * as rqCalendario from '../../../api/calendario'
import * as rqCliente from '../../../api/cliente'
import * as rqUsuario from '../../../api/usuario'

/*
 * Utilitários
 */
import calcTotalDays from '../../../utils/calc-total-days'
import checkData from '../../../utils/check-form-data'
import createTimestamp from '../../../utils/create-timestamp'
import getDate from '../../../utils/get-date'
import isSimplificado from '../../../utils/is-simplificado'
import toNum from '../../../utils/to-num'

/*
 * Outros
 */
import * as groups from '../../../groups'

/*
 * Constantes
 */
const AIRBNB_ID_STATUS     = 7
const CONFIRMADO_ID_STATUS = 3
const RESERVA_ID_STATUS    = 6

const AIRBNB_N = '0'
const AIRBNB_Y = '1'

/*
 * Campos que não são obrigatórios
 */
const NON_REQUIRED_FIELDS = [
  'description'
]

export default props => {

  /*
   * States com listas da API
   */
  const [ apartamentoList, setApartamentoList ] = useState([])
  const [ clienteList, setClienteList ] = useState([])

  /*
   * Outros states
   */
  const [ isLoading, setLoading ] = useState(true)
  const [ isReserva, setIsReserva ] = useState(false)
  const [ isConfirmado, setIsConfirmado ] = useState(false)
  const [ dialog, createDialog ] = useState({})
  const [ form, setForm ] = useState({})
  const [ isProprietario, setIsProprietatio ] = useState(null)

  const routeId = props.match.params.id
  const isEditing = typeof routeId !== 'undefined'

  const setApartamentoId = id => {
    setForm({ ...form, 'id_apartamento': id })
  }
  const setClienteId = id => {
    setForm({ ...form, 'id_cliente': id })
  }

  /**
   * Ir para página de envio de e-mail
   */
  const actionSendEmail = () => {
    props.history.push(`/calendar/email/${routeId}`)
  }

  /**
   * Fazer a remoção do agendamento
   */
  const actionDelete = () => {
    createDialog({
      open: true,
      isConfirmation: true,
      title: 'Confirmação',
      message: 'Tem certeza que deseja removê-lo?',
      onYes: () => {
        const onSuccess = () => {
          handleCloseDialog()
          createDialog({
            open: true,
            title: 'Feito',
            message: 'Removido com sucesso.',
            afterClose: () => {
              props.history.push('/')
            }
          })
        }
        const onError = () => {
          handleCloseDialog()
          createDialog({
            open: true,
            title: 'Erro',
            message: 'Não foi possível remover nesse momento.'
          })
        }

        rqCalendario.remove(routeId)
          .then(onSuccess)
          .catch(onError)
      }
    })
  }

  function actionGoToRecebimento() {
    props.history.push(`/calendar/recebimento/data/${routeId}`)
  }

  /**
   * Fechar dialogo
   */
  const handleCloseDialog = () => {
    createDialog({
      open: false
    })
  }

  const handleChangeValue = type => event => {
    const { value } = event.target

    // Como a descrição não faz alterações em nada,
    // ele pode ser alterado diretamente para
    // tentar melhorar a performance
    if (type === 'description') {
      setForm({
        ...form,
        'description': value
      })
      return
    }

    const {
      start_date,
      start_date_time,
      end_date,
      end_time_date,
      valor_diaria,
      valor_taxa,
      valor_desconto
    } = {
      ...form,
      [type]: value
    }

    var total_dia

    if (
         start_date
      && start_date_time
      && end_date
      && end_time_date
    ) {
      const ds = `${start_date} ${start_date_time}`
      const de = `${end_date} ${end_time_date}`
      
      total_dia = calcTotalDays(ds, de)
    } else {
      total_dia = form['total_dia']
    }

    const total_diaN = toNum(total_dia)
    const valor_diariaN = toNum(valor_diaria)
    const valor_taxaN = toNum(valor_taxa)
    const valor_descontoN = toNum(valor_desconto)

    setForm({
      ...form,
      total_dia,
      'valor_total': total_diaN * valor_diariaN + valor_taxaN - valor_descontoN,
      [type]: value
    })
  }

  const handleSubmit = event => {
    event.preventDefault()

    const onReqSuccess = () => {
      const title = isEditing && !isReserva
        ? 'Atualizado'
        : 'Adicionado'
      const message = isEditing && !isReserva
        ? 'O agendamento foi atualizado com sucesso.'
        : 'O agendamento foi criado com sucesso.'

      createDialog({
        open      : true,
        title     : title,
        message   : message,
        afterClose: () => props.history.push('/'),
      })
    }

    const onReqError = ({ response }) => {
      createDialog({
        open: true,
        title: 'Erro',
        message: response.data.error,
      })
    }

    const continueSubmit = () => {
      const reqBody = {
        'id_apartamento': toNum(form['id_apartamento']),
        'start_date'    : createTimestamp(form['start_date'], form['start_date_time']),
        'end_date'      : createTimestamp(form['end_date'], form['end_time_date']),
        'id_cliente'    : toNum(form['id_cliente']),
        'comissao'      : toNum(form['comissao']),
        'valor_taxa'    : toNum(form['valor_taxa']),
        'valor_desconto': toNum(form['valor_desconto']),
        'total_dia'     : toNum(form['total_dia']),
        'valor_diaria'  : toNum(form['valor_diaria']),
        'valor_total'   : toNum(form['valor_total']),
        'num_pessoas'   : toNum(form['num_pessoas']),
        'description'   : form['description'],
        'airbnb'        : form['airbnb'] === AIRBNB_Y,
      }

      var rqFn

      if (isEditing) {
        if (isReserva) {
          rqFn = rqCalendario.reservaToAgendamento(routeId, reqBody)
        } else {
          rqFn = rqCalendario.update(routeId, reqBody)
        }
      } else {
        rqFn = rqCalendario.add(reqBody)
      }

      rqFn
        .then(onReqSuccess)
        .catch(onReqError)
    }

    const stopSumbit = err => {
      console.error(err)

      createDialog({
        open: true,
        title: 'Erro',
        message: 'Algum campo não foi preenchido.',
      })
    }

    checkData(form, NON_REQUIRED_FIELDS)
      .then(continueSubmit)
      .catch(stopSumbit)
  }

  if (
       isLoading
    && apartamentoList.length > 0
    && clienteList.length > 0
  ) {
    if (isEditing) {
      rqCalendario.get(routeId).then(data => {
        setForm({
          'id_apartamento' : data['id_apartamento'],
          'start_date'     : data['start_date'].split(' ')[0] || '',
          'start_date_time': data['start_date'].split(' ')[1]  || '',
          'end_date'       : data['end_date'].split(' ')[0] || '',
          'end_time_date'  : data['end_date'].split(' ')[1] || '',
          'id_cliente'     : data['id_cliente'],
          'comissao'       : data['comissao'] || 20,
          'valor_taxa'     : data['valor_taxa'] || 150,
          'valor_desconto' : data['valor_desconto'] || 0,
          'total_dia'      : data['total_dia'] || 1,
          'valor_diaria'   : data['valor_diaria'] || 0,
          'valor_total'    : data['valor_total'] || 150,
          'num_pessoas'    : data['num_pessoas'] || 1,
          'airbnb'         : data['id_status'] === AIRBNB_ID_STATUS ? AIRBNB_Y : AIRBNB_N,
          'description'    : data['description'] || '',
        })

        switch (data['id_status']) {

          case CONFIRMADO_ID_STATUS:
            setIsConfirmado(true)
            break

          case RESERVA_ID_STATUS:
            setIsReserva(true)
            break

          // no default
        }

        return data['id_cliente']
      }).then((id_cliente) => {

        /*
         * Se o cadastro do cliente for simplificado,
         * então vai redirecionar para a página de completar cadastro.
         */
        rqCliente.get(id_cliente).then(data => {
          if (isSimplificado(data)) {
            props.history.push(`/more/clientes/completo/edit/${id_cliente}?agendamento=${routeId}`)
          }
        }).then(() => {
          setLoading(false)
        })
      })  
    } else {

      // Search params para obter a da data inicial, caso
      // exista, e iniciar o formulário com ela
      const searchParams = new URLSearchParams(props.location.search)

      /**
       * Obter { value } a partir de { property }
       */
      const valFromProp = (data, prop) => Number(
        data.find(({ property }) => property === prop).value
      )

      rqCadastros.listValores().then(data => {
        setForm({
          'id_apartamento' : null,
          'start_date'     : searchParams.get('start_date') || getDate(),
          'start_date_time': '00:00',
          'end_date'       : searchParams.get('start_date') || getDate(),
          'end_time_date'  : '23:59',
          'id_cliente'     : null,
          'comissao'       : valFromProp(data, 'comissao'),
          'valor_taxa'     : valFromProp(data, 'taxa'),
          'valor_desconto' : 0,
          'total_dia'      : 1,
          'valor_diaria'   : 0,
          'valor_total'    : 0,
          'num_pessoas'    : 1,
          'airbnb'         : AIRBNB_N,
          'description'    : ''
        })
      }).then(() => {
        setLoading(false)
      })
    }
  }

  /*
   * Obter lista de apartamentos
   */
  if (apartamentoList.length === 0) {
    rqApartamento.list().then(data => {
      const list = data.map(item => ({ label: item.nome, value: item.id}))
      setApartamentoList(list)
    })
  }

  /*
   * Obter lista de clientes
   */
  if (clienteList.length === 0) {
    rqCliente.list().then(({ data }) => {
      const list = data.map(item => ({ label: item.nome, value: item.id}))
      setClienteList(list)
    })
  }

  /*
   * Verificar se usuário é proprietário
   */
  if (isProprietario === null) {
    rqUsuario.get().then(data => {
      setIsProprietatio(
           data.groups.includes(groups.ADMIN)
        || data.groups.includes(groups.PROPRIETARIO)
      )
    })
  }

  const formFields = [
    [{
      placeholder : 'Apartamento',
      type        : 'autocomplete',
      suggestions : apartamentoList,
      defaultValue: apartamentoList.find(({ value }) => value === form['id_apartamento']),
      onChange    : ({ value: id }) => setApartamentoId(id),
    }],

    [{
      label    : 'Data inicial',
      type     : 'normal',
      inputType: 'date',
      value    : form['start_date'],
      onChange : handleChangeValue('start_date'),
    }, {
      label    : 'Hora',
      type     : 'normal',
      inputType: 'time',
      value    : form['start_date_time'],
      onChange : handleChangeValue('start_date_time'),
    }],

    [{
      label    : 'Data final',
      type     : 'normal',
      inputType: 'date',
      value    : form['end_date'],
      onChange : handleChangeValue('end_date'),
    }, {
      label    : 'Hora',
      type     : 'normal',
      inputType: 'time',
      value    : form['end_time_date'],
      onChange : handleChangeValue('end_time_date'),
    }],
  
    [{
      placeholder : 'Locador',
      type        : 'autocomplete',
      suggestions : clienteList,
      defaultValue: clienteList.find(({ value }) => value === form['id_cliente']),
      onChange    : ({ value: id }) => setClienteId(id),
    }],
    
    [{
      label    : 'Comissão',
      type     : 'prefix',
      inputType: 'number',
      prefix   : '%',
      value    : form['comissao'],
      onChange : handleChangeValue('comissao'),
    }, {
      label    : 'Taxa',
      type     : 'prefix',
      inputType: 'number',
      prefix   : 'R$',
      value    : form['valor_taxa'],
      onChange : handleChangeValue('valor_taxa'),
    }],

    [{
      label    : 'Desconto',
      type     : 'prefix',
      inputType: 'number',
      prefix   : 'R$',
      value    : form['valor_desconto'],
      onChange : handleChangeValue('valor_desconto'),
    }, {
      label    : 'Total de dias',
      type     : 'normal',
      inputType: 'number',
      value    : form['total_dia'],
      onChange : handleChangeValue('total_dia'),
      disabled : true,
    }],
    
    [{
      label    : 'Diária',
      type     : 'prefix',
      inputType: 'number',
      prefix   : 'R$',
      value    : form['valor_diaria'],
      onChange : handleChangeValue('valor_diaria'),
    }, {
      label    : 'Total',
      type     : 'normal',
      inputType: 'number',
      value    : form['valor_total'],
      onChange : handleChangeValue('valor_total'),
      disabled : true,
    }],

    [{
      label    : 'Pessoas',
      type     : 'normal',
      inputType: 'number',
      value    : form['num_pessoas'],
      onChange : handleChangeValue('num_pessoas'),
    }, {
      label   : 'Pelo Airbnb?',
      type    : 'select',
      value   : form['airbnb'],
      onChange: handleChangeValue('airbnb'),
      options : [
        { label: 'Sim', value: AIRBNB_Y },
        { label: 'Não', value: AIRBNB_N },
      ],
    }],

    [{
      label    : 'Descrição',
      type     : 'normal',
      multiline: true,
      rows     : 4,
      value    : form['description'],
      onChange : handleChangeValue('description'),
    }]
  ]

  return (
    <>
      <Dialog
        open={dialog.open}
        title={dialog.title}
        message={dialog.message}
        onClose={handleCloseDialog}
        afterClose={dialog.afterClose}
        onYes={dialog.onYes}
        isConfirmation={dialog.isConfirmation}
      />

      <Form
        mainButtonLabel={
          isEditing && !isReserva
            ? 'Atualizar'
            : 'Agendar'
        }
        isLoading={isLoading}
        fields={formFields}
        onSubmit={handleSubmit}
        headerComponent={
          <Header
            goBack={() => props.history.goBack() }
            onSendEmail={
              isEditing && !isReserva
                ? actionSendEmail
                : null
            }
            onDelete={
              isEditing && !isReserva && !isConfirmado && isProprietario
                ? actionDelete
                : null
            }
            onGoToRecebimento={
              isProprietario && (
                isEditing && !isReserva
                  ? actionGoToRecebimento
                  : null
              )
            }
          />
        }
      />
    </>
  )
}