import req from './utils/req'

/**
 * Get
 */
export const get = (id) => req({

  method: 'GET',
  route: `/transferencia/${id}`,

})

/**
 * List
 */
export const list = () => req({

  method: 'GET',
  route: `/transferencia`,

})

/**
 * Add
 */
export const add = (data) => req({

  method: 'POST',
  route: `/transferencia`,
  data

})

/**
 * Update
 */
export const update = (id, data) => req({

  method: 'PUT',
  route: `/transferencia/${id}`,
  data

})