
/**
 * Converter timestamp para uma data normalizada
 * @example
 * timestampToDate('2019-12-01 00:00:00')
 * //=> '2019-12-1'
 */
const timestampToDate = (timestamp) => {
  return timestamp
    .split(/\s/)[0]
    .split('-')
    .map(x => Number(x))
    .join('-')
}

/**
 * Verificar se uma data está entre duas datas (inicial e final)
 *
 * @params {string} date Data para verificar
 * @params {string} di Data inicial
 * @params {string} df Data final
 * @returns {boolean} Resultado da verificação
 * @examples
 * checkDate('1999-09-14', '1999-09-13', '1999-09-15')
 * //=> true
 * checkDate('1999-09-14', '2000-01-01', '2000-12-01')
 * //=> false
 */
export default (date, di, df) => {
  date = timestampToDate(date).replace(/-/g, '/')
  di = timestampToDate(di).replace(/-/g, '/')
  df = timestampToDate(df).replace(/-/g, '/')

  const timeDate = (new Date(date)).getTime()
  const timeDi = (new Date(di)).getTime()
  const timeDf = (new Date(df)).getTime()

  return timeDate >= timeDi && timeDate <= timeDf
}
