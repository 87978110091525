import React from 'react'

import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import DateRangeIcon from '@material-ui/icons/DateRange'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'

import { makeStyles } from '@material-ui/core/styles'

const useStyle = makeStyles(() => ({
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    paddingRight: 4
  },
  buttonMargin: {
    marginRight: 5,
  },
}))

const HeaderTitle = ({ title, onGetPickerDate }) => (
  <>
    <IconButton
      edge="start"
      color="inherit"
      onClick={onGetPickerDate}
    >
      <DateRangeIcon />
    </IconButton>
    <Typography variant="h6" noWrap>
      {title}
    </Typography>
  </>
)

const HeaderSeparator = () => {
  const classes = useStyle()

  return <div className={classes.grow} />
}

const HeaderActions = ({ onClickSearch, onClickFilter }) => {
  const classes = useStyle()

  return (
    <>
      <IconButton
        edge="start"
        color="inherit"
        className={classes.buttonMargin}
        onClick={onClickSearch}
      >
        <SearchIcon />
      </IconButton>
      <IconButton
        edge="start"
        color="inherit"
        onClick={onClickFilter}
      >
        <FilterListIcon />
      </IconButton>
    </>
  )
}

export default ({
  title,
  goToPage,
  toggleFilterVisibility,
  onGetPickerDate
}) => {
  const classes = useStyle()

  return (
    <AppBar position="static">
      <Toolbar className={classes.toolbar} variant="dense">
        <HeaderTitle title={title} onGetPickerDate={onGetPickerDate} />
        <HeaderSeparator />
        <HeaderActions
          onClickSearch={() => goToPage('/calendar/search')}
          onClickFilter={toggleFilterVisibility}
        />
      </Toolbar>
    </AppBar>
  )
}