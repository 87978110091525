function normalizeDate(date) {
  date.replace(/-/g, '/')
  date = date.split(' ')[0] + ' 00:00:00'
  return date
}

function isBefore12Hours(date) {
  return (new Date(date)).getHours() < 12
}

function isAfter12Hours(date) {
  return (new Date(date)).getHours() >= 12
}

/**
 * Obter a diferença entre dois dias
 */
export default function calcTotalDays(startDate, endDate) {
  const ONE_DAY = 86400000

  startDate = startDate.replace(/-/g, '/')
  endDate = endDate.replace(/-/g, '/')

  const pureStartDate = normalizeDate(startDate)
  const pureEndDate = normalizeDate(endDate)

  console.log(pureStartDate)

  const ds = (new Date(pureStartDate)).getTime()
  const de = (new Date(pureEndDate)).getTime()

  const diffDate = (new Date(de - ds)).getTime()

  var total = diffDate / ONE_DAY + 1

  if (isAfter12Hours(startDate)) {
    total = total - 1
  }
  if (isBefore12Hours(endDate)) {
    total = total - 1
  }

  return total
}