
/**
 * Juntar classes (ignorando valores não-string)
 *
 * @examples
 * cn(['foo', undefined, 'bar', 42, 'baz'])
 * //=> 'foo bar baz'
 */
export default function cn(classes) {
  if (!Array.isArray(classes)) {
    return ''
  }

  return classes
    .filter((className) => typeof className === 'string')
    .join(' ')
}