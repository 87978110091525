import React, { useState } from 'react'

/*
 * Componentes locais
 */
import Header from './header'

/*
 * Componentes globais
 */
import Dialog from '../../../components/Dialog'
import Form from '../../../components/Form'

/*
 * Utilitários da API
 */
import * as rqApartamento from '../../../api/apartamento'
import * as rqCalendario from '../../../api/calendario'
import * as rqCliente from '../../../api/cliente'
import * as rqUsuario from '../../../api/usuario'

/*
 * Utilitários
 */
import checkData from '../../../utils/check-form-data'
import createTimestamp from '../../../utils/create-timestamp'
import getDate from '../../../utils/get-date'
import isSimplificado from '../../../utils/is-simplificado'
import toNum from '../../../utils/to-num'

/*
 * Outros
 */
import * as groups from '../../../groups'

/*
 * Campos que não são obrigatórios
 */
const NON_REQUIRED_FIELDS = [
  'description'
]

/**
 * Adicionar novo cliente
 */
function addNewClient(goTo, form) {
  const NEW_CLIENT_PATH = '/more/clientes/simplificado/new'

  // Os dados do formulário serão guardados como parâmetros
  // de URL para que quando o usuário clicar em voltar os
  // dados possam ser recuperados
  const searchParams = new URLSearchParams()

  searchParams.set('from', 'reserva')
  Object
    .entries(form)
    .forEach(([ key, value ]) => {
      if (value) {
        searchParams.set(key, value)
      }
    })

  goTo(`${NEW_CLIENT_PATH}?${searchParams.toString()}`)
}

/**
 * Criar novo agendamento
 */
function createAgendamento(goTo, { id_reserva, id_cliente }) {
  rqCliente.get(id_cliente).then((data) => {
    isSimplificado(data)
      ? goTo(`/more/clientes/completo/edit/${id_cliente}?agendamento=${id_reserva}`)
      : goTo(`/calendar/edit/agendamento/${id_reserva}`)
  })
}

export default props => {

  /*
   * States com listas da API
   */
  const [ apartamentoList, setApartamentoList ] = useState([])
  const [ clienteList, setClienteList ] = useState([])
  const [ isProprietario, setIsProprietatio ] = useState(null)

  /*
   * Outros states
   */
  const [ dialog, createDialog ] = useState({})
  const [ form, setForm ] = useState({})
  const [ isLoading, setIsLoading ] = useState(true)

  const routeId = props.match.params.id
  const isEditing = typeof routeId !== 'undefined'

  const setApartamentoId = (id) => {
    setForm({ ...form, 'id_apartamento': id })
  }
  const setClienteId = (id) => {
    setForm({ ...form, 'id_cliente': id })
  }

  /**
   * Verificar exclusão de item
   */
  const actionDelete = () => {
    createDialog({
      open: true,
      isConfirmation: true,
      title: 'Confirmação',
      message: 'Tem certeza que deseja removê-lo?',
      onYes: () => {
        const onSuccess = () => {
          handleCloseDialog()
          createDialog({
            open: true,
            title: 'Feito',
            message: 'Removido com sucesso.',
            afterClose: () => {
              props.history.push('/')
            }
          })
        }
        const onError = () => {
          handleCloseDialog()
          createDialog({
            open: true,
            title: 'Erro',
            message: 'Não foi possível remover nesse momento.'
          })
        }

        rqCalendario.remove(routeId)
          .then(onSuccess)
          .catch(onError)
      }
    })
  }

  /**
   * Fechar dialogo
   */
  const handleCloseDialog = () => {
    createDialog({
      open: false
    })
  }

  /**
   * Alterar valor de campo no formulário
   */
  const handleChangeValue = (name) => (event) => {
    const { value } = event.target

    setForm({
      ...form,
      [name]: value
    })
  }

  /**
   * Manipular submit
   */
  const handleSubmit = (event) => {
    event.preventDefault()

    const onReqSuccess = () => {
      const title = isEditing
        ? 'Atualizado'
        : 'Adicionado'
      const message = isEditing
        ? 'A reserva foi atualizada com sucesso.'
        : 'A reserva foi criada com sucesso.'

      createDialog({
        open      : true,
        title     : title,
        message   : message,
        afterClose: () => props.history.push('/'),
      })
    }

    const onReqError = ({ response }) => {
      createDialog({
        open   : true,
        title  : 'Erro',
        message: response.data.error,
      })
    }

    const continueSubmit = () => {
      const reqBody = {
        'id_apartamento': toNum(form['id_apartamento']) || 0,
        'id_cliente'    : toNum(form['id_cliente']) || 0,
        'start_date'    : createTimestamp(form['start_date'], form['start_date_time']),
        'end_date'      : createTimestamp(form['end_date'], form['end_date_time']),
        'reserva'       : true,
        'description'   : form['description'] || '',
      }

      const rqFn = isEditing
        ? rqCalendario.update(routeId, reqBody)
        : rqCalendario.add(reqBody)
      
      rqFn
        .then(onReqSuccess)
        .catch(onReqError)
    }

    const stopSumbit = (err) => {
      console.error(err)

      createDialog({
        open   : true,
        title  : 'Erro',
        message: 'Algum campo não foi preenchido.',
      })
    }

    checkData(form, NON_REQUIRED_FIELDS)
      .then(continueSubmit)
      .catch(stopSumbit)
  }

  /*
   * Obter lista de apartamentos
   */
  if (apartamentoList.length === 0) {
    rqApartamento.list().then(data => {
      const list = data.map(item => ({ label: item.nome, value: item.id}))
      setApartamentoList(list)
    })
  }

  /*
   * Obter lista de locadores
   */
  if (clienteList.length === 0) {
    rqCliente.list().then(({ data }) => {
      const list = data.map(item => ({ label: item.nome, value: item.id}))
      setClienteList(list)
    })
  }

  /*
   * Verificar se usuário é proprietário
   */
  if (isProprietario === null) {
    rqUsuario.get().then(data => {
      setIsProprietatio(
           data.groups.includes(groups.ADMIN)
        || data.groups.includes(groups.PROPRIETARIO)
      )
    })
  }

  if (
       isLoading
    && apartamentoList.length > 0
    && clienteList.length > 0
    && isProprietario !== null
  ) {

    // Se estiver editando
    if (isEditing) {
      rqCalendario.get(routeId).then(data => {
        setForm({
          'id_apartamento' : data['id_apartamento'],
          'id_cliente'     : data['id_cliente'],
          'start_date'     : data['start_date'].split(' ')[0] || '',
          'end_date'       : data['end_date'].split(' ')[0] || '',
          'start_date_time': data['start_date'].split(' ')[1] || '',
          'end_date_time'  : data['end_date'].split(' ')[1] || '',
          'description'    : data['description'] || '',
        })
      }).then(() => {
        setIsLoading(false)
      })

    // Se não tiver editando o formulário ele terá a possibilidade de
    // recuperar dados da ida à página de criação de cliente simplificado
    } else {
      const searchParams = new URLSearchParams(props.location.search)

      setForm({
        'id_apartamento' : toNum(searchParams.get('id_apartamento')) || null,
        'id_cliente'     : toNum(searchParams.get('id_cliente')) || null,
        'start_date_time': searchParams.get('start_date_time') || '00:00',
        'end_date_time'  : searchParams.get('end_date_time') || '23:59',
        'start_date'     : searchParams.get('start_date') || getDate(),
        'end_date'       : searchParams.get('end_date')
                        || searchParams.get('start_date')
                        || getDate(),
        'description'    : searchParams.get('description') || '',
      })

      setIsLoading(false)
    }
  }

  const formFields = [
    [{
      placeholder: 'Apartamento',
      type: 'autocomplete',
      suggestions: apartamentoList,
      defaultValue: apartamentoList.find(({ value }) => value === form['id_apartamento']),
      onChange: ({ value: id }) => setApartamentoId(id),
    }],

    [{
      label    : 'Data inicial',
      type     : 'normal',
      inputType: 'date',
      value    : form['start_date'],
      onChange : handleChangeValue('start_date'),
    }, {
      label    : 'Hora',
      type     : 'normal',
      inputType: 'time',
      value    : form['start_date_time'],
      onChange : handleChangeValue('start_date_time'),
    }],

    [{
      label    : 'Data final',
      type     : 'normal',
      inputType: 'date',
      value    : form['end_date'],
      onChange : handleChangeValue('end_date'),
    }, {
      label    : 'Hora',
      type     : 'normal',
      inputType: 'time',
      value    : form['end_date_time'],
      onChange : handleChangeValue('end_date_time'),
    }],

    [{
      placeholder : 'Locador',
      type        : 'autocomplete',
      suggestions : clienteList,
      defaultValue: clienteList.find(({ value }) => value === form['id_cliente']),
      onChange    : ({ value: id }) => setClienteId(id),
    }],

    [{
      label    : 'Descrição',
      type     : 'normal',
      multiline: true,
      rows     : 4,
      value    : form['description'],
      onChange : handleChangeValue('description'),
    }]
  ]

  return (
    <>
      <Dialog
        open={dialog.open}
        title={dialog.title}
        message={dialog.message}
        onClose={handleCloseDialog}
        afterClose={dialog.afterClose}
        onYes={dialog.onYes}
        isConfirmation={dialog.isConfirmation}
      />

      <Form
        mainButtonLabel={
          isEditing
            ? 'Atualizar'
            : 'Reservar'
        }
        secondaryButtonLabel={
          isEditing
            ? 'Agendar'
            : null
        }
        isLoading={isLoading}
        fields={formFields}
        onSubmit={handleSubmit}
        onSecondarySubmit={
          isEditing
            ? () => createAgendamento(props.history.push, {
                id_reserva: routeId,
                id_cliente: form['id_cliente']
              })
            : false
        }
        headerComponent={(
          <Header
            goBack={() => props.history.push('/')}
            addNewCliente={
              !isEditing
                ? () => addNewClient(props.history.push, form)
                : null
            }
            onDelete={
              isEditing && isProprietario
                ? () => actionDelete()
                : null
            }
          />
        )}
      />
    </>
  )
}