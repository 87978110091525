import React from 'react'

import {
  AppBar,
  IconButton,
  Toolbar as ToolbarUI,
  Typography
} from '@material-ui/core'

import { styled } from '@material-ui/core/styles'

const Toolbar = styled(ToolbarUI)({
  paddingRight: 4,
})
const HeaderSeparator = styled('div')({
  flexGrow: 1,
})

export default ({
  title,
  startActions = [],
  endActions = [],
}) => {
  return (
    <AppBar position="static">
      <Toolbar variant="dense">
        {startActions.map(({ onClick, Icon }, index) => (
          <IconButton
            key={index}
            edge="start"
            color="inherit"
            onClick={onClick}
          >
            <Icon />
          </IconButton>
        ))}

        <Typography variant="h6" noWrap>
          {title}
        </Typography>

        <HeaderSeparator />

        {endActions.map(({ onClick, Icon }, index) => (
          <IconButton
            key={index}
            edge="start"
            color="inherit"
            onClick={onClick}
          >
            <Icon />
          </IconButton>
        ))}
      </Toolbar>
    </AppBar>
  )
}
