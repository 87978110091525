import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'

import {
  EventAvailable as EventAvailableIcon,
  Schedule as ScheduleIcon,
  Block as BlockIcon
} from '@material-ui/icons'

import Menu from '../../../components/Menu'

/*
 * Outros
 */
import * as rqUsuario from '../../../api/usuario'

/*
 * Outros
 */
import * as groups from '../../../groups'

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    'padding': theme.spacing(1, 0),
  },
  'button': {
    'width': '100%',
    '&:not(:last-child)': {
      'margin-bottom': 12.5,
    },
  },
  buttonLabel: {
    paddingLeft: 5
  },
}))

/**
 * Formatar data.
 * formatDate({ year: 2001, month: 1, day: 1 })
 * //=> '2001-01-01'
 */
function formatDate(date) {

  // Se algum item não existir ele retorna false
  if (
       !date.day
    || !date.month
    || !date.year
  ) {
    return false
  }

  const dd = date.day.toString().padStart(2, '0')
  const mm = date.month.toString().padStart(2, '0')
  const yyyy = date.year

  return `${yyyy}-${mm}-${dd}`
}

export default ({ isOpen, onClose, goToPage, date }) => {
  const [ isProprietario, setIsProprietario ] = useState(null)

  const classes = useStyles()
  const formattedDate = formatDate(date)

  if (isProprietario === null) {
    rqUsuario.get().then(data => {
      setIsProprietario(
           data.groups.includes(groups.ADMIN)
        || data.groups.includes(groups.PROPRIETARIO)
      )
    })
  }

  const goToAgendamentoPage = () => {
    formattedDate
      ? goToPage(`/calendar/new/agendamento?start_date=${formattedDate}`)
      : goToPage('/calendar/new/agendamento')
  }
  const goToReservaPage = () => {
    formattedDate
      ? goToPage(`/calendar/new/reserva?start_date=${formattedDate}`)
      : goToPage('/calendar/new/reserva')
  }
  const goToBloqueioPage = () => {
    formattedDate
      ? goToPage(`/calendar/bloqueio?start_date=${formattedDate}`)
      : goToPage('/calendar/bloqueio')
  }

  return (
    <Menu isOpen={isOpen} onClose={onClose} position="bottom">
      <div className={classes.buttonGroup}>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={goToAgendamentoPage}
        >
          <EventAvailableIcon />
          <span className={classes.buttonLabel}>Agendamento</span>
        </Button>

        <Button
          size="medium"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={goToReservaPage}
        >
          <ScheduleIcon />
          <span className={classes.buttonLabel}>Reserva</span>
        </Button>

        {isProprietario && (
          <Button
            size="medium"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={goToBloqueioPage}
          >
            <BlockIcon />
            <span className={classes.buttonLabel}>Bloqueio</span>
          </Button>
        )}
      </div>
    </Menu>
  )
}