import React from 'react'

// material-ui utils
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  mainContent: {
    flex: 1,
    overflow: 'auto',
  },
}))

export default ({ headerComponent, children }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {headerComponent}
      
      <div className={classes.mainContent}>
        {children}
      </div>
    </div>
  )
}
