import React, { useEffect, useState } from 'react'

import { styled } from '@material-ui/core/styles'

import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

import Typography from '@material-ui/core/Typography'

import Header from './header'

import Dialog from '../../../components/Dialog'
import FloatButton from '../../../components/FloatButton'
import List from '../../../components/List'
import Loading from '../../../components/Loading'
import PageWithHeader from '../../../components/PageWithHeader'

import * as rqRecebimento from '../../../api/recebimento'
import * as rqUsuario from '../../../api/usuario'

/*
 * Outros
 */
import * as groups from '../../../groups'

const MessageContainer = styled('div')({
  padding: '20px 0' 
})

function toCurrency(value) {
  return value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })
}

export default (props) => {
  const [ dialog, createDialog ] = useState({})
  const [ list, setList ] = useState()
  const [ loading, setLoading ] = useState(true)

  const routeId = props.match.params.id

  function PageHeader() {
    return (
      <Header
        goBack={() => props.history.push(`/calendar/edit/agendamento/${routeId}`)}
        goInformations={() => props.history.push(`/calendar/recebimento/data/${routeId}?viewing=true`)}
      />
    )
  }

  function ListItems() {
    return <List menuItems={list} />
  }

  function EmptyMessage() {
    return (
      <MessageContainer>
        <Typography align="center" color="textSecondary">
          Nada recebindo ainda.
        </Typography>
      </MessageContainer>
    )
  }

  /**
   * Fechar dialogo
   */
  const handleCloseDialog = () => {
    createDialog({
      open: false
    })
  }

  function loadListData() {
    rqRecebimento.get(routeId).then(data => {
      if (data.itens.length === 0) {
        setList([])
      } else {
        setList([
          {
            content: data['itens'].map(item => ({
              label: `${item['data_recebimento']} (${toCurrency(item['valor_recebido'])})`,
              onClick: () => {},
              action: {
                onClick: () => removeItem(item.id),
                Icon: <DeleteOutlineIcon />
              }
            }))
          }
        ])
      }
    }).then(() => {
      setLoading(false)
    })
  }

  /**
   * Remover item de recebimento
   */
  function removeItem(id) {
    createDialog({
      open: true,
      title: 'Confirmar',
      message: 'Tem certeza?',
      isConfirmation: true,
      onYes: () => {
        rqRecebimento.removeItem(id).then(() => {
          createDialog({
            open: true,
            title: 'Sucesso',
            message: 'Removido com sucesso!'
          })
          loadListData(true)
        }).catch(() => {
          createDialog({
            open: true,
            title: 'Erro',
            message: 'Não foi possível remover!'
          })
        })
      }
    })
  }

  useEffect(() => {
    loadListData()
  })

  /*
   * Bloquear essa página para quem não proprietário ou admin.
   */
  rqUsuario.get().then(data => {
    if (!data.groups.includes(groups.PROPRIETARIO) && !data.groups.includes(groups.ADMIN)) {
      props.history.goBack()
    }
  })

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <Dialog
        open={dialog.open}
        title={dialog.title}
        message={dialog.message}
        onClose={handleCloseDialog}
        afterClose={dialog.afterClose}
        onYes={dialog.onYes}
        isConfirmation={dialog.isConfirmation}
      />

      <FloatButton onClick={() => props.history.push(`/calendar/recebimento/add/${routeId}`)}>
        <AddIcon />
      </FloatButton>

      <PageWithHeader headerComponent={<PageHeader />}>
        {list.length > 0 ? (
          <ListItems />
        ) : (
          <EmptyMessage />
        )}
      </PageWithHeader>
    </>
  )
}