import React, { useState } from 'react'

/*
 * Componentes locais
 */
import Header from './header'

/*
 * Componentes globais
 */
import Dialog from '../../../components/Dialog'
import Form from '../../../components/Form'

/*
 * Utilitários da API
 */
import * as rqRecebimento from '../../../api/recebimento'
import * as rqUsuario from '../../../api/usuario'

/*
 * Outros
 */
import * as groups from '../../../groups'

/*
 * Utilitários
 */
import checkData from '../../../utils/check-form-data'

/*
 * Campos que não são obrigatórios
 */
const NON_REQUIRED_FIELDS = []

export default props => {

  /*
   * Outros states
   */
  const [ dialog, createDialog ] = useState({})
  const [ userList, setUserList ] = useState([])
  const [ isProprietario, setIsProprietario ] = useState(false)

  const [ form, setForm ] = useState({
    'id_corretor': null
  })

  const calendarioId = props.match.params.id
  
  /**
   * Fechar dialogo
   */
  const handleCloseDialog = () => {
    createDialog({
      open: false
    })
  }

  /**
   * Atualizar ID do corretor
   */
  const setCorretorId = (id) => {
    setForm({ ...form, 'id_corretor': id })
  }

  /**
   * Manipular submit
   */
  const handleSubmit = event => {
    event.preventDefault()

    const onReqSuccess = () => {
      createDialog({
        open      : true,
        title     : 'Criado',
        message   : 'Transferência registrada!',
        afterClose: () => window.location.assign(`/calendar/recebimento/list/${calendarioId}`),
      })
    }

    const onReqError = ({ response }) => {
      console.error(response)

      createDialog({
        open   : true,
        title  : 'Erro',
        message: response.data.error,
      })
    }

    const continueSubmit = () => {
      const reqBody = {
        'id_corretor': form['id_corretor']
      }

      rqRecebimento.registerTransfer(calendarioId, reqBody)
        .then(onReqSuccess)
        .catch(onReqError)
    }

    const stopSumbit = err => {
      console.error(err)

      createDialog({
        open   : true,
        title  : 'Erro',
        message: 'Algum campo não foi preenchido.',
      })
    }

    checkData(form, NON_REQUIRED_FIELDS)
      .then(continueSubmit)
      .catch(stopSumbit)
  }

  // Obter lista de proprietários
  if (userList.length === 0) {
    rqUsuario.listCorretor().then(data => {
      const list = data.map(item => ({ label: item.name, value: item.id}))
      setUserList(list)
    })
  }

  /*
   * Bloquear essa página para quem não for proprietário ou admin.
   */
  rqUsuario.get().then(data => {
    const proprietario = !data.groups.includes(groups.PROPRIETARIO) && !data.groups.includes(groups.ADMIN)
    setIsProprietario(!proprietario)

    if (proprietario) {
      props.history.goBack()
    }
  })

  const formFields = [
    [
      {
        placeholder : 'Corretor',
        type        : 'autocomplete',
        suggestions : userList,
        defaultValue: userList.find(({ value }) => value === form['id_corretor']),
        onChange    : ({ value: id }) => setCorretorId(id),
        disabled    : !isProprietario
      },
    ],
  ]

  return (
    <>
      <Dialog
        open={dialog.open}
        title={dialog.title}
        message={dialog.message}
        onClose={handleCloseDialog}
        afterClose={dialog.afterClose}
        onYes={dialog.onYes}
        isConfirmation={dialog.isConfirmation}
      />

      <Form
        mainButtonLabel="Registrar transferência"
        isLoading={false}
        fields={formFields}
        onSubmit={handleSubmit}
        headerComponent={(
          <Header
            goBack={() => props.history.goBack()}
          />
         )}
      />
    </>
  )
}