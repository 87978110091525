import req from './utils/req'

/**
 * Get
 */
export const get = (id) => req({

  method: 'GET',
  route: `/recebimento/calendario/${id}`,

})

/**
 * Update
 */
export const update = (id, data) => req({

  method: 'PUT',
  route: `/recebimento/calendario/${id}`,
  data

})

/**
 * Create
 */
export const create = (data) => req({

  method: 'POST',
  route: `/recebimento`,
  data,

})

/**
 * Create item
 */
export const createItem = (data) => req({

  method: 'POST',
  route: '/recebimento/item',
  data,

})

/**
 * Remove item
 */
export const removeItem = (id) => req({

  method: 'DELETE',
  route: `/recebimento/item/${id}`,

})

/**
 * Register transfer
 */
export const registerTransfer = (id, data) => req({

  method: 'POST',
  route: `/recebimento/transferencia/calendario/${id}`,
  data,

})