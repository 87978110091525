import React from 'react'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import DeleteIcon from '@material-ui/icons/Delete'

import Header from '../../../components/Header'

export default ({ goBack, onDelete }) => (
  <Header
    title="Bloqueio"
    startActions={[
      {
        Icon: ArrowBackIcon,
        onClick: goBack
      }
    ]}
    endActions={onDelete ? [{
      Icon: DeleteIcon,
      onClick: onDelete
    }] : []}
  />
)