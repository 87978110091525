import React, { useState } from 'react'

/*
 * Componentes locais
 */
import Header from './header'

/*
 * Componentes globais
 */
import Dialog from '../../../components/Dialog'
import Form from '../../../components/Form'

/*
 * Utilitários da API
 */
import * as rqCalendario from '../../../api/calendario'
import * as rqCliente from '../../../api/cliente'
import * as rqRecebimento from '../../../api/recebimento'
import * as rqUsuario from '../../../api/usuario'

/*
 * Utilitários
 */
import checkData from '../../../utils/check-form-data'
import toNum from '../../../utils/to-num'

/*
 * Outros
 */
import * as groups from '../../../groups'

/*
 * Campos que não são obrigatórios
 */
const NON_REQUIRED_FIELDS = [
  'obs'
]

export default props => {

  /*
   * States com listas da API
   */
  const [ clienteList, setClienteList ] = useState([])

  /*
   * Outros states
   */
  const [ creating, setCreating ] = useState(false)
  const [ dialog, createDialog ] = useState({})
  const [ form, setForm ] = useState({})
  const [ isLoading, setLoading ] = useState(true)

  const routeId = props.match.params.id

  const setClienteId = id => {
    setForm({ ...form, 'id_cliente': id })
  }

  /**
   * Fechar dialogo
   */
  const handleCloseDialog = () => {
    createDialog({
      open: false
    })
  }

  /**
   * Alterar valor de campo no formulário
   */
  const handleChangeValue = name => event => {
    const { value } = event.target

    setForm({
      ...form,
      [name]: value
    })
  }

  /**
   * Manipular submit
   */
  const handleSubmit = event => {
    event.preventDefault()

    const onReqSuccess = (a) => {
      createDialog({
        open      : true,
        title     : 'Criado',
        message   : creating
          ? 'O recebimento foi criado com sucesso.'
          : 'O recebimento foi atualizado com sucesso.',
        afterClose: () => props.history.goBack(),
      })
    }

    const onReqError = ({ response }) => {
      console.error(response)

      createDialog({
        open   : true,
        title  : 'Erro',
        message: response.data.error,
      })
    }

    const continueSubmit = () => {
      const reqBody = {
        'id_calendario': toNum(routeId),
        'id_cliente'   : toNum(form['id_cliente']),
        'obs'          : form['obs'],
      }

      if (creating) {
        rqRecebimento.create(reqBody)
          .then(onReqSuccess)
          .catch(onReqError)
      } else {
        rqRecebimento.update(routeId, { 'obs': form['obs'] })
          .then(onReqSuccess)
          .catch(onReqError)
      }
    }

    const stopSumbit = err => {
      console.error(err)

      createDialog({
        open   : true,
        title  : 'Erro',
        message: 'Algum campo não foi preenchido.',
      })
    }

    checkData(form, NON_REQUIRED_FIELDS)
      .then(continueSubmit)
      .catch(stopSumbit)
  }

  /*
   * Bloquear essa página para quem não proprietário
   */
  rqUsuario.get().then(data => {
    if (!data.groups.includes(groups.PROPRIETARIO) && !data.groups.includes(groups.ADMIN)) {
      props.history.goBack()
    }
  })

  /*
   * Obter lista de clientes
   */
  if (clienteList.length === 0) {
    rqCliente.list().then(({ data }) => {
      const list = data.map(item => ({ label: item.nome, value: item.id}))
      setClienteList(list)
    })
  }

  if (isLoading && clienteList.length > 0) {
    rqRecebimento.get(routeId).then((data) => {
      if (data) {
        if (data.recebimento) {
          const searchParams = new URLSearchParams(props.location.search)

          // Se o usuário quiser só visualizar o recebimento será
          // passado o parâmetro de URL "?viewing=true"
          if (searchParams.get('viewing') === 'true') {
            setForm({
              'id_cliente': data.recebimento['id_cliente'],
              'obs'       : data.recebimento['obs'] || '',
            })
          } else {

            // ...Caso contrário redireciona o usuário
            // para a página de listagem de recebimento
            props.history.push(`/calendar/recebimento/list/${routeId}`)
          }
        } else {
          createDialog({
            open   : true,
            title  : 'Erro',
            message: 'Não foi possível obter os dados com sucesso.',
          })
        }
      } else {
        rqCalendario.get(routeId).then((calendarioData) => {
          const clientId = calendarioData['id_cliente']
          setForm({
            'id_cliente': clientId,
            'obs'       : '',
          })
          setCreating(true)
        }).then(() => {
          setLoading(false)
        }).catch(() => {
          createDialog({
            open   : true,
            title  : 'Erro',
            message: 'Não foi possível obter os dados com sucesso.',
          })
        })
      }
    }).then(() => {
      if (form['id_cliente']) {
        setLoading(false)
      }
    })
  }

  const formFields = [
    [{
      placeholder : 'Cliente',
      type        : 'autocomplete',
      suggestions : clienteList,
      defaultValue: clienteList.find(({ value }) => value === form['id_cliente']),
      onChange    : ({ value: id }) => setClienteId(id),
      disabled    : true
    }],

    [{
      label    : 'Observação',
      type     : 'normal',
      multiline: true,
      rows     : 4,
      value    : form['obs'],
      onChange : handleChangeValue('obs'),
    }]
  ]

  return (
    <>
      <Dialog
        open={dialog.open}
        title={dialog.title}
        message={dialog.message}
        onClose={handleCloseDialog}
        afterClose={dialog.afterClose}
        onYes={dialog.onYes}
        isConfirmation={dialog.isConfirmation}
      />

      <Form
        mainButtonLabel={creating ? 'Criar' : 'Salvar'}
        isLoading={isLoading}
        fields={formFields}
        onSubmit={handleSubmit}
        headerComponent={(
          <Header
            goBack={() => props.history.goBack()}
          />
         )}
      />
    </>
  )
}