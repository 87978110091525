import React, { useState } from 'react'

/*
 * Componentes locais
 */
import Header from './header'

/*
 * Componentes globais
 */
import Dialog from '../../../components/Dialog'
import Form from '../../../components/Form'

/*
 * Utilitários da API
 */
import * as rqApartamento from '../../../api/apartamento'
import * as rqBloqueio from '../../../api/bloqueio'
import * as rqUsuario from '../../../api/usuario'

/*
 * Utilitários
 */
import checkData from '../../../utils/check-form-data'
import getDate from '../../../utils/get-date'
import toNum from '../../../utils/to-num'

/*
 * Outros
 */
import * as groups from '../../../groups'

/*
 * Campos que não são obrigatórios
 */
const NON_REQUIRED_FIELDS = [
  'description'
]

export default props => {

  /*
   * States com listas da API
   */
  const [ apartamentoList, setApartamentoList ] = useState([])

  /*
   * Outros states
   */
  const [ dialog, createDialog ] = useState({})
  const [ form, setForm ] = useState({})
  const [ isProprietario, setIsProprietario ] = useState(null)
  const [ isLoading, setIsLoading ] = useState(true)

  const routeId = props.match.params.id
  const isEditing = typeof routeId !== 'undefined'

  const setApartamentoId = id => {
    setForm({ ...form, 'id_apartamento': id })
  }

  /**
   * Fechar dialogo
   */
  const handleCloseDialog = () => {
    createDialog({
      open: false
    })
  }

  /**
   * Fazer a remoção do bloqueio
   */
  const actionDelete = () => {
    createDialog({
      open: true,
      isConfirmation: true,
      title: 'Confirmação',
      message: 'Tem certeza que deseja removê-lo?',
      onYes: () => {
        const onSuccess = () => {
          handleCloseDialog()
          createDialog({
            open: true,
            title: 'Feito',
            message: 'Removido com sucesso.',
            afterClose: () => {
              props.history.push('/')
            }
          })
        }
        const onError = () => {
          handleCloseDialog()
          createDialog({
            open: true,
            title: 'Erro',
            message: 'Não foi possível remover nesse momento.'
          })
        }

        rqBloqueio.remove(routeId)
          .then(onSuccess)
          .catch(onError)
      }
    })
  }

  /**
   * Alterar valor de campo no formulário
   */
  const handleChangeValue = name => event => {
    const { value } = event.target

    setForm({
      ...form,
      [name]: value
    })
  }

  /**
   * Manipular submit
   */
  const handleSubmit = event => {
    event.preventDefault()

    const onReqSuccess = () => {
      const title = isEditing
        ? 'Atualizado'
        : 'Adicionado'
      const message = isEditing
        ? 'O bloqueio foi atualizado com sucesso.'
        : 'O bloqueio foi criado com sucesso.'

      createDialog({
        open      : true,
        title     : title,
        message   : message,
        afterClose: () => props.history.push('/'),
      })
    }

    const onReqError = ({ response }) => {
      console.error(response)

      createDialog({
        open   : true,
        title  : 'Erro',
        message: response.data.error,
      })
    }

    const continueSubmit = () => {
      const reqBody = {
        'id_apartamento': toNum(form['id_apartamento']) || 0,
        'start_date'    : form['start_date'],
        'end_date'      : form['end_date'],
        'description'   : form['description'] || '',
      }

      const rqFn = isEditing
        ? rqBloqueio.update(routeId, reqBody)
        : rqBloqueio.add(reqBody)
      
      rqFn
        .then(onReqSuccess)
        .catch(onReqError)
    }

    const stopSumbit = err => {
      console.error(err)

      createDialog({
        open   : true,
        title  : 'Erro',
        message: 'Algum campo não foi preenchido.',
      })
    }

    checkData(form, NON_REQUIRED_FIELDS)
      .then(continueSubmit)
      .catch(stopSumbit)
  }

  /*
   * Bloquear essa página para quem não proprietário ou admin.
   */
  rqUsuario.get().then(data => {
    setIsProprietario(
         data.groups.includes(groups.PROPRIETARIO)
      || data.groups.includes(groups.ADMIN)
    )
  })

  /*
   * Obter lista de apartamentos
   */
  if (apartamentoList.length === 0) {
    rqApartamento.list().then(data => {
      const list = data.map(item => ({ label: item.nome, value: item.id}))
      setApartamentoList(list)
    })
  }

  if (
       isLoading
    && apartamentoList.length > 0
  ) {

    // Se estiver editando
    if (isEditing) {
      rqBloqueio.get(routeId).then(data => {
        setForm({
          'id_apartamento': data['id_apartamento'],
          'start_date'    : (data['start_date'] || '').split(' ')[0],
          'end_date'      : (data['end_date'] || '').split(' ')[0],
          'description'   : data['description'] || '',
        })
      }).then(() => {
        setIsLoading(false)
      })
    } else {
      const searchParams = new URLSearchParams(props.location.search)

      setForm({
        'id_apartamento': null,
        'start_date'    : searchParams.get('start_date') || getDate(),
        'end_date'      : searchParams.get('start_date') || getDate(),
        'description'   : '',
      })

      setIsLoading(false)
    }
  }

  const formFields = [
    [{
      placeholder : 'Apartamento',
      type        : 'autocomplete',
      suggestions : apartamentoList,
      defaultValue: apartamentoList.find(({ value }) => value === form['id_apartamento']),
      onChange    : ({ value: id }) => setApartamentoId(id),
      disabled    : !isProprietario,
    }],

    [{
      label    : 'Data inicial',
      type     : 'normal',
      inputType: 'date',
      value    : form['start_date'],
      onChange : handleChangeValue('start_date'),
      disabled : !isProprietario,
    }, {
      label    : 'Data final',
      type     : 'normal',
      inputType: 'date',
      value    : form['end_date'],
      onChange : handleChangeValue('end_date'),
      disabled : !isProprietario,
    }],

    [{
      label    : 'Descrição',
      type     : 'normal',
      multiline: true,
      rows     : 4,
      value    : form['description'],
      onChange : handleChangeValue('description'),
      disabled : !isProprietario,
    }]
  ]

  return (
    <>
      <Dialog
        open={dialog.open}
        title={dialog.title}
        message={dialog.message}
        onClose={handleCloseDialog}
        afterClose={dialog.afterClose}
        onYes={dialog.onYes}
        isConfirmation={dialog.isConfirmation}
      />

      <Form
        mainButtonLabel={
          isEditing
            ? 'Atualizar'
            : 'Bloquear'
        }
        isLoading={isLoading}
        fields={formFields}
        onSubmit={isProprietario && handleSubmit}
        headerComponent={(
          <Header
            goBack={() => props.history.push('/')}
            onDelete={
              isEditing && isProprietario
                ? () => actionDelete()
                : null
            }
          />
        )}
      />
    </>
  )
}