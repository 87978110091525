import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'

import FilterableSelect from '../../../components/FilterableSelect'
import Menu from '../../../components/Menu'
import Select from '../../../components/Select'

import * as rqApartamento from '../../../api/apartamento'
import * as rqCadastros from '../../../api/cadastros'

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(1.5),
  },
  fullWidth: {
    width: '100%',
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}))

export default ({
  isOpen = false,
  onClose = () => {},
  onFilter = () => {}
}) => {
  const classes = useStyles()

  const [ apartmentList, setApartmentList ] = useState([])
  const [ statusList, setStatusList ] = useState([])

  const [ apartmentId, setApartmentId ] = useState()
  const [ statusId, setStatusId ] = useState(0)

  if (apartmentList.length === 0) {
    rqApartamento.list().then(data => {
      setApartmentList(data.map(({ id, nome }) => ({
        label: nome,
        value: id
      })))
    })
  }

  if (statusList.length === 0) {
    rqCadastros.listStatus().then(data => {
      setStatusList(data.map(({ id, descricao }) => ({
        label: descricao,
        value: id
      })))
    })
  }

  return (
    <Menu
      isOpen={isOpen}
      onClose={onClose}
      position="top"
    >
      <div className={classes.container}>
        <FormControl className={classes.formControl}>
          <FilterableSelect
            placeholder="Apartamento..."
            suggestions={apartmentList}
            onChange={({ value }) => setApartmentId(value)}
          />
        </FormControl>

        <FormControl className={classes.formControl}>
          <Select
            label="Status..."
            options={statusList}
            value={statusId}
            onChange={(e) => setStatusId(Number(e.target.value))}
          />
        </FormControl>
        

        <FormControl className={classes.fullWidth}>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => onFilter(apartmentId, statusId || statusList[0].value)}
          >
            Filtrar
          </Button>
        </FormControl>
      </div>
    </Menu>
  )
}