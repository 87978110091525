import req from './utils/req'

/**
 * Get
 */
export const get = (id) => req({

  method: 'GET',
  route: `/proprietario/${id}`,

})

/**
 * List
 */
export const list = () => req({

  method: 'GET',
  route: `/proprietario`,

})
