
/**
 * Verificar se uma cor é escura
 *
 * @params {string} Uma cor em hexadecimal
 * @returns {string} Resultado da verificação
 * @example
 * isDarkColor('#ffffff') //=> false
 * isDarkColor('#000000') //=> true
 */
export default (color) => {
  const [ ,, xRed, xGreen, xBlue ] = color.match(/^#?((.{2})(.{2})(.{2}))$/)

  const rgbRed = parseInt(xRed, 16)
  const rgbGreen = parseInt(xGreen, 16)
  const rgbBlue = parseInt(xBlue, 16)

  return (rgbRed + rgbGreen + rgbBlue) / 3 < 255 / 2
}