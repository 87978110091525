
/**
 * Verificar com base nos dados recebidos do servidor se
 * determinado cadastro de usuário é simplificado
 */
export default function isSimplificado(data) {
  return Object
    .keys(data)
    .filter(key => key !== 'updated_at')
    .filter(key => key !== 'created_at')
    .filter(key => data[key])
    .length === 3
}