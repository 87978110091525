import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import AddIcon from '@material-ui/icons/Add'

import {
  Button,
  FormControl
} from '@material-ui/core'

import Header from './header'
import DatePicker from './date-picker'
import MenuFilter from './menu-filter'
import MenuNew from './menu-new'
import Calendar from './calendar'

import FloatButton from '../../../components/FloatButton'
import ListAgendas from '../../../components/list-agendas'
import Loading from '../../../components/Loading'
import PageWithHeader from '../../../components/PageWithHeader'

import checkDate from '../../../utils/check-date'
import getCurrentDate from '../../../utils/get-current-date'

import * as rqCalendario from '../../../api/calendario'

import months from '../../../data/months.json'

const useStyles = makeStyles(theme => ({
  mainContainer: {
    margin: theme.spacing(1.5),
  },
}))

function getCalendarDate() {
  return JSON.parse(window.sessionStorage.getItem('date')) || getCurrentDate()
}

function setCalendarDate(date) {
  date.year = parseInt(date.year)
  date.month = parseInt(date.month)

  window.sessionStorage.setItem('date', JSON.stringify(date))
}

const MainContainer = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.mainContainer}>
      {children}
    </div>
  )
}

export default (props) => {
  const classes = useStyles()

  const [ menuFilterIsOpen, setMenuFilterVisibility ] = useState(false)
  const [ menuNewIsOpen, setMenuNewVisibility ] = useState(false)
  const [ currentItemsList, setCurrentItemsList ] = useState([])
  const [ data, setData ] = useState()
  const [ datePickerOpen, setDatePickerOpen ] = useState(false)
  const [ currentDate, setCurrentDate ] = useState(getCalendarDate())

  const [ isFiltering, setIsFiltering ] = useState(false)

  const goToPage = props.history.push

  if (!data) {
    rqCalendario.list(currentDate.year, currentDate.month)
      .then((data) => {
        setData(data || [])
      })

    return <Loading />
  }
  
  const clearListItems = () => {
    setCurrentItemsList([])
    setIsFiltering(false)
  }

  const filterByDate = (date) => {
    clearListItems()

    setTimeout(() => {
      setCurrentItemsList(data.filter(item => {
        return checkDate(
          `${date.year}-${date.month}-${date.day}`,
          item['start_date'],
          item['end_date']
        )
      }))
    }, 0)
  }

  const filterByApartmentAndStatus = (apartmentId, statusId) => {
    if (!apartmentId) return

    clearListItems()
    setIsFiltering(true)
    setMenuFilterVisibility(false)

    data.filter(({
      id_apartamento,
      id_status
    }) => {
      return id_apartamento === apartmentId
          && id_status === statusId
    })

    setTimeout(() => {
      setCurrentItemsList(data.filter(({
        id_apartamento,
        id_status
      }) => {
        return id_apartamento === apartmentId
            && id_status === statusId
      }))
    }, 0)
  }

  const updateDate = (year, month) => {
    setCurrentDate({ year, month })
    setCalendarDate({ year, month })
    setDatePickerOpen(false)
    clearListItems()
    setData(false)
  } 

  return (
    <PageWithHeader
      headerComponent={(
        <Header
          title={`${months[currentDate.month - 1]} ${currentDate.year}`}
          goToPage={goToPage}
          toggleFilterVisibility={() => setMenuFilterVisibility(!menuFilterIsOpen)}
          onGetPickerDate={() => setDatePickerOpen(true)}
        />
      )}
    >
      {isFiltering && (
        <div className={classes.mainContainer}>
          <FormControl style={{ width: '100%' }}>
            <Button
              type="submit"
              color="primary"
              size="medium"
              variant="outlined"
              onClick={() => clearListItems()}
            >
              Limpar filtro
            </Button>
          </FormControl>
        </div>
      )}

      <DatePicker
        open={datePickerOpen}
        onOkay={updateDate}
        date={currentDate}
        onClose={() => setDatePickerOpen(false)}
      />
      <MenuFilter
        isOpen={menuFilterIsOpen}
        onClose={() => setMenuFilterVisibility(false)}
        onFilter={filterByApartmentAndStatus}
      />
      <MenuNew
        isOpen={menuNewIsOpen}
        onClose={() => setMenuNewVisibility(false)}
        goToPage={goToPage}
        date={currentDate}
      />

      <MainContainer>
        {!isFiltering && (
          <Calendar
            calendarData={data}
            date={currentDate}
            onUpdateMonth={updateDate}
            onChange={(date) => {
              setCurrentDate(date)
              setCalendarDate(date)
              filterByDate(date)
            }}
          />
        )}

        <ListAgendas
          list={currentItemsList}
          goTo={goToPage}
        />
      </MainContainer>

      <FloatButton onClick={() => setMenuNewVisibility(true)}>
        <AddIcon />
      </FloatButton>
    </PageWithHeader>
  )
}