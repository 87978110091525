import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { Route, Switch, withRouter, BrowserRouter as Router } from 'react-router-dom'

import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import Paper from '@material-ui/core/Paper'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'

import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import EventIcon from '@material-ui/icons/Event'
import MenuBookIcon from '@material-ui/icons/MenuBook'

import { createMuiTheme, makeStyles, styled } from '@material-ui/core/styles'

import Loading from './components/Loading'

import Login from './pages/login'
import NotFound from './pages/404'

import Calendar from './pages/calendar/main'
import CalendarBloqueioForm from './pages/calendar/bloqueio-form'
import CalendarEmail from './pages/calendar/email'
import CalendarNewAgendamento from './pages/calendar/new-agendamento'
import CalendarNewReserva from './pages/calendar/new-reserva'
import CalendarSearch from './pages/calendar/search'
import CalendarRecebimentoItem from './pages/calendar/recebimento-item'
import CalendarRecebimentoTransf from './pages/calendar/recebimento-transf'
import CalendarRecebimentoList from './pages/calendar/recebimento-list'
import CalendarRecebimento from './pages/calendar/recebimento'
import CalendarSearchList from './pages/calendar/search-list'

import MorePage from './pages/more/main'
import MoreApartamentosEdit from './pages/more/apartamentos-edit'
import MoreApartamentosList from './pages/more/apartamentos-list'
import MoreClientesEditCompleto from './pages/more/clientes-edit-completo'
import MoreClientesEditSimplificado from './pages/more/clientes-edit-simplificado'
import MoreClientesList from './pages/more/clientes-list'
import MoreTransferenciasEdit from './pages/more/transferencias-edit'
import MoreTransferenciasList from './pages/more/transferencias-list'
import MoreValores from './pages/more/valores'

import Account from './pages/account'

import { TABS_HEIGHT } from './constants'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    overflow: 'auto',
  },
}))

const theme = createMuiTheme({
  palette: {
    primary: { main: '#16a085' },
    secondary: { main: '#db5345' },
  },
})

const Label = styled(Typography)({
  fontSize: '0.7em',
})

const CustomTab = styled(Tab)({
  paddingTop: 0,
  paddingBottom: 0,
  minHeight: TABS_HEIGHT,
})

const getPathByIndex = (index) => {
  switch (index) {
    case 0: return '/'
    case 1: return '/more'
    case 2: return '/account'
    // no default
  }
}

const getIndexByPath = (path) => {
  if (/^\/calendar/.test(path)) return 0
  if (/^\/more/.test(path)) return 1
  if (/^\/account/.test(path)) return 2
  return 0
}

const App = withRouter((props) => {
  const goToPage = props.history.push
  const locationPath = props.location.pathname

  const classes = useStyles()
  const [ currTab, setCurrTab ] = useState(getIndexByPath(locationPath))
  
  const isLogged = 'jwt_token' in window.localStorage

  const onChangeTab = (_, newValue) => {

    // Atualiza a tab que foi selecionada
    setCurrTab(newValue)

    const pathIndex = getPathByIndex(newValue)

    // Redirecona o usuário para a tab escolhida
    goToPage(pathIndex)
  }

  if (props.location.pathname === '/login' && currTab !== 0) {

    // Deixa na tab do calendário
    setCurrTab(0)
  }

  if (!isLogged && props.location.pathname !== '/login') {
    props.history.push('/login')

    return <Loading />
  }

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.container}>
        <div className={classes.main}>
          <Switch>
            <Route path="/login" exact component={Login} />

            <Route path="/" exact component={Calendar} />
            <Route path="/calendar/bloqueio" exact component={CalendarBloqueioForm} />
            <Route path="/calendar/bloqueio/:id" exact component={CalendarBloqueioForm} />
            <Route path="/calendar/edit/agendamento/:id" exact component={CalendarNewAgendamento} />
            <Route path="/calendar/new/agendamento" exact component={CalendarNewAgendamento} />
            <Route path='/calendar/edit/reserva/:id' exact component={CalendarNewReserva} />
            <Route path='/calendar/email/:id' exact component={CalendarEmail} />
            <Route path='/calendar/new/reserva' exact component={CalendarNewReserva} />
            <Route path='/calendar/recebimento/data/:id' exact component={CalendarRecebimento} />
            <Route path='/calendar/recebimento/add/:id' exact component={CalendarRecebimentoItem} />
            <Route path='/calendar/recebimento/transf/:id' exact component={CalendarRecebimentoTransf} />
            <Route path='/calendar/recebimento/list/:id' exact component={CalendarRecebimentoList} />
            <Route path='/calendar/search' exact component={CalendarSearch} />
            <Route path='/calendar/search/list' exact component={CalendarSearchList} />

            <Route path="/more" exact component={MorePage} />
            <Route path="/more/apartamentos/edit/:id" exact component={MoreApartamentosEdit} />
            <Route path="/more/apartamentos/list" exact component={MoreApartamentosList} />
            <Route path="/more/clientes/completo/edit/:id" exact component={MoreClientesEditCompleto} />
            <Route path="/more/clientes/completo/new" exact component={MoreClientesEditCompleto} />
            <Route path="/more/clientes/list" exact component={MoreClientesList} />
            <Route path="/more/clientes/simplificado/edit/:id" exact component={MoreClientesEditSimplificado} />
            <Route path="/more/clientes/simplificado/new" exact component={MoreClientesEditSimplificado} />
            <Route path="/more/transferencias/edit/:id" exact component={MoreTransferenciasEdit} />
            <Route path="/more/transferencias/list" exact component={MoreTransferenciasList} />
            <Route path="/more/transferencias/new" exact component={MoreTransferenciasEdit} />
            <Route path="/more/valores" exact component={MoreValores} />

            <Route path='/account' exact component={Account} />

            <Route component={NotFound} />
          </Switch>
        </div>

        {isLogged && (
          <Paper className="hide-with-keyboard">
            <Tabs
              value={currTab}
              onChange={onChangeTab}
              variant="fullWidth"
              indicatorColor="secondary"
              textColor="secondary"
              aria-label="icon tabs"
            >
              <CustomTab icon={<EventIcon />} label={<Label>Calendário</Label>} />
              <CustomTab icon={<MenuBookIcon />} label={<Label>Mais opções</Label>} />
              <CustomTab icon={<AccountCircleIcon />} label={<Label>Minha conta</Label>} />
            </Tabs>
          </Paper>
         )}
      </div>
    </MuiThemeProvider>
  )
})

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.querySelector('#root')
)
