import React from 'react'

import { styled } from '@material-ui/core/styles'

import Fab from '@material-ui/core/Fab'

import { TABS_HEIGHT } from '../constants'

const FabRightBottom = styled(Fab)({
  position: 'fixed',
  right: 20,
  bottom: TABS_HEIGHT + 20,
})

export default function FloatButton({ children, onClick }) {
  return (
    <FabRightBottom
      aria-label="add"
      color="primary"
      className="float-button"
      onClick={onClick}
    >
      {children}
    </FabRightBottom>
  )
}