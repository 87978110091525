import React from 'react'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import Header from '../../../components/Header'

export default ({ goBack }) => (
  <Header
  	title="Transferências"
  	startActions={[
      {
        Icon: ArrowBackIcon,
        onClick: goBack
      }
    ]}
  />
)