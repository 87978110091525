import React from 'react'

import {
  ArrowBack as ArrowBackIcon,
  Info as InfoIcon
} from '@material-ui/icons'

import Header from '../../../components/Header'

export default ({ goBack, goInformations }) => (
  <Header
    title="Recebimento"
    startActions={[
      {
        Icon: ArrowBackIcon,
        onClick: goBack
      }
    ]}
    endActions={[
      {
        Icon: InfoIcon,
        onClick: goInformations
      }
    ]}
  />
)