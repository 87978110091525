import React from 'react'

import { styled } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const Container = styled('div')({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
})

export default () => (
  <Container>
    <CircularProgress />
  </Container>
)