/**
 * Obter data atual no formatd DD/MM/YYYY
 * @example
 * getCurrentDate() // Supondo que hoje seja 01/01/2001.
 * //=> {
 *   year: 2001,
 *   month: 1,
 *   day: 1	
 * }
 */
export default function getCurrentDate() {
  const date = new Date()
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  return {
	day,
	month,
	year
  }
}