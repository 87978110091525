import React from 'react'

import {
  ArrowBack as ArrowBackIcon,
  Delete as DeleteIcon,
  PersonAdd as PersonAddIcon
} from '@material-ui/icons'

import Header from '../../../components/Header'

import noop from '../../../utils/noop'

const TITLE = 'Reserva'

export default ({
  goBack = noop,
  addNewCliente = noop,
  onDelete = noop
}) => {

  /*
   * start
   */
  const startActions = [{
    Icon: ArrowBackIcon,
    onClick: goBack
  }]

  /*
   * end
   */
  const endActions = []
    .concat(addNewCliente && {
      Icon: PersonAddIcon,
      onClick: addNewCliente
    })
    .concat(onDelete && {
      Icon: DeleteIcon,
      onClick: onDelete
    })
    .filter(Boolean)

  return (
    <Header
      title={TITLE}
      startActions={startActions}
      endActions={endActions}
    />
  )
}