import React, { useState } from 'react'

/*
 * Componentes locais
 */
import Header from './header'

/*
 * Componentes globais
 */
import Dialog from '../../../components/Dialog'
import Form from '../../../components/Form'

/*
 * Utilitários da API
 */
import * as rqRecebimento from '../../../api/recebimento'
import * as rqUsuario from '../../../api/usuario'

/*
 * Outros
 */
import * as groups from '../../../groups'

/*
 * Utilitários
 */
import checkData from '../../../utils/check-form-data'
import getDate from '../../../utils/get-date'
import toNum from '../../../utils/to-num'

/*
 * Constantes
 */
const EMPTY = ''

/*
 * Campos que não são obrigatórios
 */
const NON_REQUIRED_FIELDS = []

export default props => {

  /*
   * Outros states
   */
  const [ dialog, createDialog ] = useState({})
  const [ form, setForm ] = useState({
    data_recebimento: getDate(),
    valor_recebido  : EMPTY
  })

  const routeId = props.match.params.id

  /**
   * Fechar dialogo
   */
  const handleCloseDialog = () => {
    createDialog({
      open: false
    })
  }

  /**
   * Alterar valor de campo no formulário
   */
  const handleChangeValue = name => event => {
    const { value } = event.target

    setForm({
      ...form,
      [name]: value
    })
  }

  /**
   * Manipular submit
   */
  const handleSubmit = event => {
    event.preventDefault()

    const onReqSuccess = (data) => {
      if (data['pagamento_corretor'] === 'N') {
        createDialog({
          open          : true,
          title         : 'Criado',
          message       : 'Deseja registrar pagamento?',
          afterClose    : () => props.history.goBack(),
          onYes         : () => window.location.assign(`/calendar/recebimento/transf/${routeId}`),
          isConfirmation: true,
        })
      } else {
        createDialog({
          open      : true,
          title     : 'Criado',
          message   : 'O recebimento foi criado com sucesso.',
          afterClose: () => props.history.goBack(),
        })
      }
    }

    const onReqError = ({ response }) => {
      console.error(response)

      createDialog({
        open   : true,
        title  : 'Erro',
        message: response.data.error,
      })
    }

    const continueSubmit = () => {
      const reqBody = {
        'data_recebimento': form['data_recebimento'],
        'id_calendario'   : routeId,
        'valor_recebido'  : toNum(form['valor_recebido']),
      }

      rqRecebimento.createItem(reqBody)
        .then(onReqSuccess)
        .catch(onReqError)
    }

    const stopSumbit = err => {
      console.error(err)

      createDialog({
        open   : true,
        title  : 'Erro',
        message: 'Algum campo não foi preenchido.',
      })
    }

    checkData(form, NON_REQUIRED_FIELDS)
      .then(continueSubmit)
      .catch(stopSumbit)
  }

  /*
   * Bloquear essa página para quem não proprietário ou admin.
   */
  rqUsuario.get().then(data => {
    if (!data.groups.includes(groups.PROPRIETARIO) && !data.groups.includes(groups.ADMIN)) {
      props.history.goBack()
    }
  })

  const formFields = [
    [{
      label    : 'Data',
      type     : 'normal',
      inputType: 'date',
      value    : form['data_recebimento'],
      onChange : handleChangeValue('data_recebimento'),
      required : true,
    }],

    [{
      label    : 'Valor recebido',
      type     : 'normal',
      inputType: 'number',
      value    : form['valor_recebido'],
      onChange : handleChangeValue('valor_recebido'),
      required : true,
    }],
  ]

  return (
    <>
      <Dialog
        open={dialog.open}
        title={dialog.title}
        message={dialog.message}
        onClose={handleCloseDialog}
        afterClose={dialog.afterClose}
        onYes={dialog.onYes}
        isConfirmation={dialog.isConfirmation}
      />

      <Form
        mainButtonLabel="Adicionar item"
        isLoading={false}
        fields={formFields}
        onSubmit={handleSubmit}
        headerComponent={(
          <Header
            goBack={() => props.history.goBack()}
          />
         )}
      />
    </>
  )
}