import React, { useState } from 'react'

/*
 * Ícones do Material-UI
 */
import SendIcon from '@material-ui/icons/Send'

/*
 * Componentes do Material-UI
 */
import PaperUI from '@material-ui/core/Paper'

/*
 * Utilitários do Material-UI
 */
import { styled } from '@material-ui/core/styles'

/*
 * Componentes locais
 */
import Header from './header'

/*
 * Componentes globais
 */
import Dialog from '../../../components/Dialog'
import FloatButton from '../../../components/FloatButton'
import Loading from '../../../components/Loading'
import PageWithHeader from '../../../components/PageWithHeader'

/*
 * Utilitários da API
 */
import * as rqEmail from '../../../api/email'

/*
 * Utilitários
 */
import noop from '../../../utils/noop'

const Root = styled('div')({
  margin: 12,
})
const Paper = styled(PaperUI)({
  padding: 10
})

export default props => {

  /*
   * States
   */
  const [ dialog, createDialog ] = useState({})
  const [ emailContent, setEmailContent ] = useState()

  const routeId = props.match.params.id

  /**
   * Fechar dialogo
   */
  const handleCloseDialog = () => {
    createDialog({
      open: false
    })
  }

  if (!emailContent) {
    rqEmail.view(routeId).then(data => {
      setEmailContent(data)
    })

    return <Loading />
  }

  /**
   * Manipular submit
   */
  const handleSubmit = event => {
    event.preventDefault()

    const onReqSuccess = () => {
      createDialog({
        open      : true,
        title     : 'Enviado',
        message   : 'O e-mail foi enviado com sucesso.',
        afterClose: () => props.history.goBack(),
      })
    }

    const onReqError = ({ response }) => {
      console.error(response)

      createDialog({
        open   : true,
        title  : 'Erro',
        message: response.data.error,
      })
    }

    rqEmail.send(routeId)
      .then(onReqSuccess)
      .catch(onReqError)
  }

  return (
    <>
      <Dialog
        open={dialog.open}
        title={dialog.title}
        message={dialog.message}
        onClose={handleCloseDialog}
        afterClose={() => (dialog.afterClose || noop)()}
      />

      <FloatButton onClick={handleSubmit}>
        <SendIcon />
      </FloatButton>

      <PageWithHeader headerComponent={
        <Header goBack={() => props.history.goBack()} />
      }>
        <Root>
          <Paper square>
            <div
              dangerouslySetInnerHTML={{
                __html: emailContent
              }}
            />
          </Paper>
        </Root>
      </PageWithHeader>
    </>
  )
}