import React, { useState } from 'react'

import Header from './header'

import Dialog from '../../../components/Dialog'
import Form from '../../../components/Form'
import Loading from '../../../components/Loading'

import * as rqApartamento from '../../../api/apartamento'
import * as rqCadastros from '../../../api/cadastros'
import * as rqProprietario from '../../../api/proprietario'
import * as rqUsuario from '../../../api/usuario'

import * as groups from '../../../groups'

import checkData from '../../../utils/check-form-data'
import noop from '../../../utils/noop'

export default (props) => {
  const goBack = () => props.history.push('/more/apartamentos/list')
  
  const apartmentId = props.match.params.id

  const [ isProprietario, setProprietario ] = useState(null)
  const [ ownerList, setOwnerList ] = useState([])
  const [ cityList, setCityList ] = useState([])
  const [ dialog, createDialog ] = useState({})
  const [ formData, setFormData ] = useState({
    apartment      : '',
    ownerId        : null,
    neighborhood   : '',
    street         : '',
    streetNumber   : 0,
    cityId         : null,
    apartmentNumber: 0,
    peopleNumber   : 0,
  })

  const nonRequiredFields = [
    'description'
  ]

  if (isProprietario === null) {
    rqUsuario.get().then(data => {
      setProprietario(
           data.groups.includes(groups.ADMIN)
        || data.groups.includes(groups.PROPRIETARIO)
      )
    })
    return <Loading />
  }

  const setOwnerId = (ownerId) => {
    setFormData({ ...formData, ownerId })
  }
  const setCityId = (cityId) => {
    setFormData({ ...formData, cityId })
  }

  const handleClose = () => {
    createDialog({
      open: false
    })
  }

  const handleChangeValue = (type) => (event) => {
    const { value } = event.target

    setFormData({
      ...formData,
      [type]: value
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const onReqSuccess = (data) => {
      createDialog({
        open: true,
        title: 'Editado',
        message: 'O apartamento foi editado com sucesso.',
        afterClose: goBack,
      })
    }
    const onReqError = ({ response }) => {
      let message = response && response.data && response.data.error
        ? response.data.error
        : 'Erro interno.'

      createDialog({
        open: true,
        title: 'Erro',
        message,
      })
    }
    const continueSubmit = () => {
      const reqBody = {
        nome: formData.apartment,
        rua: formData.street,
        numero: formData.streetNumber,
        num_apto: formData.apartmentNumber,
        bairro: formData.neighborhood,
        id_cidade: formData.cityId,
        id_estado: 15, // TODO: Remover da API essa opção
        id_proprietario: formData.ownerId,
        num_pessoas: formData.peopleNumber,
        status: 'Y',
      }

      rqApartamento.update(apartmentId, reqBody)
        .then(onReqSuccess)
        .catch(onReqError)
    }
    const stopSumbit = () => {
      createDialog({
        open: true,
        title: 'Erro',
        message: 'Algum campo não foi preenchido.',
      })
    }

    checkData(formData, nonRequiredFields)
      .then(continueSubmit)
      .catch(stopSumbit)
  }

  const onTypeCityName = (event) => {
    const MAX_LENGTH = 5
    const value = event.target.value

    if (value.length >= MAX_LENGTH) {
      rqCadastros.filterCidade(5, value).then((data) => {
        const list = data.map(item => ({
          label: `${item.nome}/${item.uf}`,
          value: item.id
        }))
        if (list.length > 0) {
          setCityList(list)
        }
      })
    }
  }

  // Obter lista de proprietários
  if (ownerList.length === 0) {
    rqProprietario.list().then(data => {
      const list = data.map(item => ({ label: item.nome, value: item.id}))
      setOwnerList(list)
    })
  }

  // Obter informações do apartamento
  if (!formData.ownerId && ownerList.length > 0) {
    rqApartamento.get(apartmentId).then(data => {
      setFormData({
        apartment      : data.nome,
        ownerId        : data.id_proprietario,
        neighborhood   : data.bairro,
        street         : data.rua,
        streetNumber   : data.numero,
        cityId         : data.id_cidade,
        apartmentNumber: data.num_apto,
        peopleNumber   : data.num_pessoas,
      })
      return data.id_cidade
    }).then(cityId => {
      if (cityId) {
        rqCadastros.getCidade(cityId).then(item => {
          setCityList([{
            label: `${item.nome}/${item.uf}`,
            value: item.id
          }])
        })
      }
    })
  }

  const formFields = [
    [
      {
        label: 'Nome do apartamento',
        type: 'normal',
        value: formData.apartment,
        onChange: handleChangeValue('apartment'),
        disabled: !isProprietario,
      },
    ],
    [
      {
        placeholder: 'Proprietário',
        type: 'autocomplete',
        suggestions: ownerList,
        defaultValue: ownerList.find(({ value }) => value === formData.ownerId),
        onChange: ({ value: id }) => setOwnerId(id),
        disabled: !isProprietario,
      },
    ],
    [
      {
        label: 'Bairro',
        type: 'normal',
        inputType: 'text',
        value: formData.neighborhood,
        onChange: handleChangeValue('neighborhood'),
        disabled: !isProprietario,
      }
    ],
    [
      {
        label: 'Rua',
        type: 'normal',
        inputType: 'text',
        value: formData.street,
        onChange: handleChangeValue('street'),
        disabled: !isProprietario,
      },
      {
        label: 'Número',
        type: 'normal',
        inputType: 'number',
        value: formData.streetNumber,
        onChange: handleChangeValue('streetNumber'),
        disabled: !isProprietario,
      },
    ],
    [
      {
        placeholder: 'Cidade',
        type: 'autocomplete',
        suggestions: cityList,
        defaultValue: cityList.find(({ value }) => value === formData.cityId),
        onChange: ({ value: id }) =>  setCityId(id),
        onKeyUp: onTypeCityName,
        disabled: !isProprietario,
      },
    ],
    [
      {
        label: 'Ap.to Núm.',
        type: 'normal',
        inputType: 'text',
        value: formData.apartmentNumber,
        onChange: handleChangeValue('apartmentNumber'),
        disabled: !isProprietario,
      },
      {
        label: 'Máximo',
        type: 'normal',
        inputType: 'number',
        value: formData.peopleNumber,
        onChange: handleChangeValue('peopleNumber'),
        disabled: !isProprietario,
      },
    ],
  ]

  return (
    <>
      <Dialog
        open={dialog.open}
        title={dialog.title}
        message={dialog.message}
        onClose={handleClose}
        afterClose={() => (dialog.afterClose || noop)()}
      />

      <Form
        mainButtonLabel="Salvar edição"
        isLoading={
             ownerList.length === 0
          || cityList.length === 0
          || !formData.ownerId
          || !formData.cityId
        }
        fields={formFields}
        onSubmit={isProprietario ? handleSubmit : false}
        headerComponent={<Header goBack={goBack} />}
      />
    </>
  )
}