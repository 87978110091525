import React, { Fragment } from 'react'

import { styled } from '@material-ui/core/styles'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography
} from '@material-ui/core'


const Root = styled('div')({
  margin: 10,
  marginTop: 20,
})
const Card = styled('div')({
  '&:not(:last-child)': {
    marginBottom: 20,
  },
})
const ListHeader = styled(Typography)({
  padding: '0 10px',
  marginBottom: 5,
  color: 'gray',
})
const ListTitle = styled(ListItemText)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

function eachCardContent({ label, action = {}, onClick, Icon }, index, content) {
  return (
    <Fragment key={index}>
      <ListItem button onClick={onClick}>
        {Icon && (
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
        )}

        <ListTitle primary={label} />

        <ListItemSecondaryAction>
          <IconButton disabled={!action.onClick} onClick={action.onClick} edge="end">
            {action.Icon || <ArrowForwardIcon />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      
      {content.length - 1 > index && (
        <Divider component="li" />
      )}
    </Fragment>
  )
}

function eachCard({ title, content }, index) {
  return (
    <Card key={index}>
      {title && (
        <ListHeader>
          {title}
        </ListHeader>
      )}

      <Paper square>
        <List>
          {content
            .filter(({hidden}) => !hidden)
            .map(eachCardContent)}
        </List>
      </Paper>
    </Card>
  )
}

export default ({ menuItems }) => {
  return (
    <Root>
      {menuItems.map(eachCard)}
    </Root>
  )
}