import React from 'react'

import {
  ArrowBack as ArrowBackIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
  MonetizationOn as MonetizationOnIcon
} from '@material-ui/icons'

import Header from '../../../components/Header'

export default ({ goBack, onSendEmail, onDelete, onGoToRecebimento }) => (
  <Header
    title="Agendamento"
    startActions={[
      {
        Icon: ArrowBackIcon,
        onClick: goBack
      }
    ]}
    endActions={
      []
      .concat(onSendEmail && {
        Icon: EmailIcon,
        onClick: onSendEmail
      })
      .concat(onDelete && {
        Icon: DeleteIcon,
        onClick: onDelete
      })
      .concat(onGoToRecebimento && {
        Icon: MonetizationOnIcon,
        onClick: onGoToRecebimento
      })
      .filter(Boolean)
    }
  />
)