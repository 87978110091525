import React, { useState } from 'react'

import Header from './header'

import Dialog from '../../../components/Dialog'
import Form from '../../../components/Form'
import Loading from '../../../components/Loading'

import * as rqTransferencia from '../../../api/transferencia'
import * as rqUsuario from '../../../api/usuario'

import * as groups from '../../../groups'

import checkData from '../../../utils/check-form-data'
import getDate from '../../../utils/get-date'
import noop from '../../../utils/noop'

export default (props) => {
  const goBack = () => props.history.push('/more/transferencias/list')
  const transferId = props.match.params.id

  const [ userList, setUserList ] = useState([])
  const [ dialog, createDialog ] = useState({})
  const [ isProprietario, setProprietario ] = useState(null)
  const [ formData, setFormData ] = useState({
    userId      : null,
    transferDate: getDate(),
    value       : 0,
    description : '',
  })

  const nonRequiredFields = []

  const setUserId = (userId) => {
    setFormData({ ...formData, userId })
  }

  const handleClose = () => {
    createDialog({
      open: false
    })
  }

  const handleChangeValue = (type) => (event) => {
    const { value } = event.target

    setFormData({
      ...formData,
      [type]: value
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const onReqSuccess = (data) => {
      createDialog({
        open: true,
        title: 'Editado',
        message: transferId === undefined
          ? 'A transferência foi adicionada com sucesso.' 
          : 'A transferência foi editada com sucesso.',
        afterClose: goBack,
      })
    }
    const onReqError = ({ response }) => {
      let message = response && response.data && response.data.error
        ? response.data.error
        : 'Erro interno.'

      createDialog({
        open: true,
        title: 'Erro',
        message,
      })
    }
    const continueSubmit = () => {
      const reqBody = {
        id_corretor       : formData.userId,
        data_transferencia: `${formData.transferDate} 00:00:00`,
        valor             : formData.value,
        descricao         : formData.description,
      }

      // Se for undefined então está criando um novo
      if (typeof transferId === 'undefined') {
        rqTransferencia.add(reqBody)
          .then(onReqSuccess)
          .catch(onReqError)
      } else {
        rqTransferencia.update(transferId, reqBody)
          .then(onReqSuccess)
          .catch(onReqError)
      }
    }
    const stopSumbit = () => {
      createDialog({
        open: true,
        title: 'Erro',
        message: 'Algum campo não foi preenchido.',
      })
    }

    checkData(formData, nonRequiredFields)
      .then(continueSubmit)
      .catch(stopSumbit)
  }

  if (isProprietario === null) {
    rqUsuario.get().then(data => {
      setProprietario(
           data.groups.includes(groups.ADMIN)
        || data.groups.includes(groups.PROPRIETARIO)
      )
    })
    return <Loading />
  }

  // Obter lista de proprietários
  if (userList.length === 0) {
    rqUsuario.list().then(data => {
      const list = data.map(item => ({ label: item.name, value: item.id}))
      setUserList(list)
    })
  }

  // Obter informações da transferência
  if (
       transferId !== undefined
    && !formData.userId
    && userList.length > 0
  ) {
    rqTransferencia.get(transferId).then(data => setFormData({
      userId      : data.id_corretor,
      transferDate: data.data_transferencia.split(' ')[0],
      value       : data.valor,
      description : data.descricao,
    }))
  }

  const formFields = [
    [
      {
        placeholder : 'Corretor',
        type        : 'autocomplete',
        suggestions : userList,
        defaultValue: userList.find(({ value }) => value === formData.userId),
        onChange    : ({ value: id }) => setUserId(id),
        disabled    : !isProprietario,
      },
    ],
    [
      {
        label    : 'Data',
        type     : 'normal',
        inputType: 'date',
        value    : formData.transferDate,
        onChange : handleChangeValue('transferDate'),
        disabled : !isProprietario,
      },
      {
        label    : 'Valor',
        type     : 'normal',
        inputType: 'number',
        value    : formData.value,
        onChange : handleChangeValue('value'),
        disabled : !isProprietario,
      },
    ],
    [
      {
        label    : 'Descrição',
        type     : 'normal',
        inputType: 'text',
        value    : formData.description,
        onChange : handleChangeValue('description'),
        disabled : !isProprietario,
      },
    ],
  ]

  return (
    <>
      <Dialog
        open={dialog.open}
        title={dialog.title}
        message={dialog.message}
        onClose={handleClose}
        afterClose={() => (dialog.afterClose || noop)()}
      />

      <Form
        mainButtonLabel={
          transferId === undefined
            ? 'Adicionar'
            : "Salvar edição"
        }
        isLoading={
             userList.length === 0
          || (transferId !== undefined && !formData.userId)
        }
        fields={formFields}
        onSubmit={isProprietario && handleSubmit}
        headerComponent={<Header goBack={goBack} />}
      />
    </>
  )
}