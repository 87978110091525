import React, {useState} from 'react'

import Header from './header'
import List from '../../../components/List'
import PageWithHeader from '../../../components/PageWithHeader'
import Loading from '../../../components/Loading'

/*
 * API 
 */
import * as rqUsuario from '../../../api/usuario'

import * as groups from '../../../groups'

import {
  Apartment as ApartmentIcon,
  MonetizationOn as MonetizationOnIcon,
  People as PeopleIcon,
  TransferWithinAStation as TransferWithinAStationIcon
} from '@material-ui/icons'


export default (props) => {
  const goTo = props.history.push

  const [isProprietario, setProprietario] = useState(null)

  if (isProprietario === null) {
    rqUsuario.get().then(data => {
      setProprietario(
           data.groups.includes(groups.ADMIN)
        || data.groups.includes(groups.PROPRIETARIO)
      )
    })
    return <Loading />
  }

  const menuItems = [
    {
      title: 'Cadastros',
      content: [
        {
          label: 'Apartamentos',
          onClick: () => goTo('/more/apartamentos/list'),
          Icon: ApartmentIcon,
        },
        {
          label: 'Clientes',
          onClick: () => goTo('/more/clientes/list'),
          Icon: PeopleIcon,
        },
      ],
    },
    {
      title: 'Financeiro',
      content: [
        {
          label: 'Transferências',
          onClick: () => goTo('/more/transferencias/list'),
          Icon: TransferWithinAStationIcon,
        },
        {
          label: 'Valores Padrão',
          onClick: () => goTo('/more/valores'),
          Icon: MonetizationOnIcon,
          hidden: !isProprietario
        },
      ],
    },
  ]

  return (
    <PageWithHeader headerComponent={<Header />}>
      <List menuItems={menuItems} />
    </PageWithHeader>
  )
}