import req from './utils/req'

/**
 * Get
 */
export const get = (id) => req({

  method: 'GET',
  route: `/cliente/${id}`,

})

/**
 * List
 */
export const list = () => req({

  method: 'GET',
  route: `/cliente?page=1&per_page=100`,

})

/**
 * Add
 */
export const add = (data) => req({

  method: 'POST',
  route: `/cliente`,
  data,

})


/**
 * Update
 */
export const update = (id, data) => req({

  method: 'PUT',
  route: `/cliente/${id}`,
  data,

})

