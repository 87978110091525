
/*
 * URL base para a API
 */
export const API_URL = process.env.REACT_APP_API_URL

/**
 * Obter o token JWT registrado no navegador
 */
export function getJwtToken() {
  return window.localStorage.getItem('jwt_token')
}
