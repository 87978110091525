import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'

import PersonIcon from '@material-ui/icons/Person'
import PeopleIcon from '@material-ui/icons/People'

import Menu from '../../../components/Menu'

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    'padding': theme.spacing(1, 0),
  },
  'button': {
    'width': '100%',
    '&:not(:last-child)': {
      'margin-bottom': 12.5,
    },
  },
  buttonLabel: {
    paddingLeft: 5
  },
}))

export default ({ isOpen, onClose, goToPage }) => {
  const classes = useStyles()

  const goToNewSimplified = () => {
    goToPage('/more/clientes/simplificado/new')
  }
  const goToNewComplete = () => {
    goToPage('/more/clientes/completo/new')
  }

  return (
    <Menu isOpen={isOpen} onClose={onClose} position="bottom">
      <div className={classes.buttonGroup}>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={goToNewSimplified}
        >
          <PersonIcon />
          <span className={classes.buttonLabel}>Simplificado</span>
        </Button>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={goToNewComplete}
        >
          <PeopleIcon />
          <span className={classes.buttonLabel}>Completo</span>
        </Button>
      </div>
    </Menu>
  )
}