import req from './utils/req'

/**
 * Get
 */
export const get = (id) => req({

  method: 'GET',
  route: `/bloqueio/${id}`,

})

/**
 * Add
 */
export const add = (data) => req({

  method: 'POST',
  route: `/bloqueio`,
  data,

})

/**
 * Remove
 */
export const remove = (id) => req({

  method: 'DELETE',
  route: `/bloqueio/${id}`,

})

/**
 * Update
 */
export const update = (id, data) => req({

  method: 'PUT',
  route: `/bloqueio/${id}`,
  data,

})