import req from './utils/req'

/**
 * Get
 */
export const get = (id) => req({

  method: 'GET',
  route: `/apartamento/${id}`,

})

/**
 * List
 */
export const list = () => req({

  method: 'GET',
  route: '/apartamento',

})

/**
 * Update
 */
export const update = (id, data) => req({

  method: 'PUT',
  route: `/apartamento/${id}`,
  data,

})