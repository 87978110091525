import React from 'react'

// Material-UI utils
import { styled, makeStyles } from '@material-ui/core/styles'

// Material-UI components
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'

// Utils
import isDarkColor from '..//utils/is-dark-color'

// API
import listStatus from '..//api/list-status'

const useStyles = makeStyles(theme => ({
  paperContainer: {
    padding: theme.spacing(1, 2),

    '&:not(:last-child)': {
      marginBottom: 10
    },
  },
}))

const Text = styled(Typography)({
  lineHeight: 1.5
})

/**
 * Obter "dd/mm/aaaa" em um data no formato "aaaa-mm-dd hh:mm:ss"
 *
 * @params {string} datetime Data no formato "aaaa-mm-dd hh:mm:ss"
 * @returns {string} Data no formato "dd/mm/aaaa"
 * @example
 * normalizeDatetime("2001-01-01 00:00:00")
 * //=> "01/01/2001"
 */
const normalizeDatetime = (datetime) => {
  const SPACE = ' '
  const dateJust = datetime.split(SPACE)[0]
  
  return dateJust
    .split('-')
    .reverse()
    .join('/')
}

/**
 * Extrair nome do cliente e do apartamento a partir do título.
 *
 * @params {string} title Titulo com dados recebindo do banco de dados.
 * @returns {object} Nome do cliente e do apartamento.
 * @example
 * getNameAndApartment('Nome: Fulano de Tal - Apartamento: Cosme Velho')
 * //=> {
 *   name: 'Fulano de Tal',
 *   apartment: 'Cosme Velho'
 * }
 */
const getNameAndApartment = (title) => {
  const [ preName, preApartment ] = title.split(' - ')
  const name = preName.replace(/^.+:\s/, '')
  const apartment = preApartment.replace(/^.+:\s/, '')

  return {
    name,
    apartment
  }
}

export default ({
  list = [],
  emptyMessage,
  goTo
}) => {
  const classes = useStyles()

  const redirectUser = (id_status, id) => {
    const ID_BLOQUEIO = 5
    const ID_RESERVA = 6

    switch (id_status) {
      case ID_BLOQUEIO:
        goTo(`/calendar/bloqueio/${id}`)
        break
      case ID_RESERVA:
        goTo(`/calendar/edit/reserva/${id}`)
        break
      default:
        goTo(`/calendar/edit/agendamento/${id}`)
    }
  }

  if (list.length === 0) {
    return (
      <Typography style={{
        marginTop: 25,
        textAlign: 'center',
        color: 'gray'
      }}>
        {emptyMessage || 'Nada encontrado.'}
      </Typography>
    )
  }

  return (
    <>
      {list.map(({
        id,
        id_status,
        title,
        start_date,
        end_date,
        color,
      }) => {
        const startDate = normalizeDatetime(start_date)
        const endDate = normalizeDatetime(end_date)
        const { name, apartment } = getNameAndApartment(title)

        return (
          <Slide
            key={id}
            direction="up"
            in={true}
            timeout={75}
            mountOnEnter
            unmountOnExit
          >
            <Paper
              key={id}
              square
              className={classes.paperContainer}
              onClick={() => redirectUser(id_status, id)}
            >
              <Chip
                size="small"
                label={listStatus[id_status]}
                style={{
                  marginBottom: 10,
                  backgroundColor: color,
                  color: isDarkColor(color) ? 'white' : 'black',
                }}
              />

              <Text variant="subtitle1">{name}</Text>
              <Text variant="subtitle2">{apartment}</Text>
              <Text variant="overline">{startDate} ― {endDate}</Text>
            </Paper>
          </Slide>
        )
      })}
    </>
  )
}