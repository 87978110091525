import React from 'react'

import { styled } from '@material-ui/core/styles'

import TypographyUI from '@material-ui/core/Typography'

const Container = styled('div')({
  position: 'relative',
  height: '100%',
})
const Typography = styled(TypographyUI)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap'
})

export default function () {
  return (
    <Container>
      <Typography color="textSecondary">
        Página inexistente!
      </Typography>
    </Container>
  )
}