import React from 'react'

import {
  Button,
  Dialog as DialogUI,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'

export default function Dialog({
  title = '',
  message = '',
  open = false,
  afterClose = () => {},
  onClose = () => {},
  isConfirmation = false,
  onYes = () => {}
}) {
  const handleClose = () => {
    onClose()
    afterClose()
  }

  return (
    <DialogUI open={open} onClose={handleClose} fullWidth maxWidth="xl">
      <DialogTitle>
        {title}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      
      {isConfirmation ? (
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            Não
          </Button>
          <Button variant="contained" color="primary" onClick={onYes} autoFocus>
            Sim
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button color="primary" onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      )}

    </DialogUI>
  )
}