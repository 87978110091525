import React, { useState } from 'react'

import AddIcon from '@material-ui/icons/Add'
import PeopleIcon from '@material-ui/icons/People'
import PersonIcon from '@material-ui/icons/Person'

import * as rqCliente from '../../../api/cliente'

import Header from './header'
import MenuNew from './menu-new'
import FloatButton from '../../../components/FloatButton'
import List from '../../../components/List'
import Loading from '../../../components/Loading'
import PageWithHeader from '../../../components/PageWithHeader'

import isSimplificado from '../../../utils/is-simplificado'

export default (props) => {
  const goTo = props.history.push
  const goBack = () => goTo('/more')

  const [ menuIsOpen, setMenuIsOpen ] = useState(false)
  const [ list, setList ] = useState()

  if (!list) {
    rqCliente.list().then(({ data = [] }) => {
      data = data.sort((a, b) => {
        if (a.nome < b.nome) return -1
        if (a.nome > b.nome) return 1
        return 0
      })

      setList([
        {
          content: data.map((clientData) => {
            const isSimplified = isSimplificado(clientData)

            return {
              label: clientData.nome,
              onClick: isSimplified
                ? () => goTo(`/more/clientes/simplificado/edit/${clientData.id}`)
                : () => goTo(`/more/clientes/completo/edit/${clientData.id}`),
              Icon: isSimplified
                ? PersonIcon
                : PeopleIcon
            }
          })
        }
      ])
    })

    return <Loading />
  }

  return (
    <PageWithHeader headerComponent={<Header goBack={goBack} />}>
      <List menuItems={list} />

      <MenuNew
        isOpen={menuIsOpen}
        onClose={() => setMenuIsOpen(false)}
        goToPage={goTo}
      />

      <FloatButton onClick={() => setMenuIsOpen(true)}>
        <AddIcon />
      </FloatButton>
    </PageWithHeader>
  )
}