import React, { useState } from 'react'

import { styled } from '@material-ui/core/styles'

import {
  Button,
  Dialog as DialogUI,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl as FormControlUI,
  Grid
} from '@material-ui/core'

import Select from '../../../components/Select'

import getCurrentDate from '../../../utils/get-current-date'

import months from '../../../data/months.json'

const GRID_SPACING = 2

const FormControl = styled(FormControlUI)({
  width: '100%',
})

function getMonthOptions() {
  return months.map((label, index) => ({
    label,
    value: index + 1
  }))
}

function getYearOptions() {

  // Quantidade de anos que serão exibidos,
  // deve ser um número ímpar sempre.
  const NUMBER = 11

  return Array(NUMBER)
    .fill()
    .map((_, i) => (getCurrentDate().year - (NUMBER - 1) / 2 + i))
    .map(value => ({
      label: value,
      value
    }))
}

export default function DatePicker({
  open = true,
  onOkay = () => {},
  onClose = () => {},
  date = {}
}) {
  const [ month, setMonth ] = useState(date.month)
  const [ year, setYear ] = useState(date.year)

  return (
    <DialogUI open={open} onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle>
        Selecionar mês
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={GRID_SPACING}>
          <Grid item xs={6}>
            <FormControl>
              <Select
                label="Mês"
                value={month}
                options={getMonthOptions()}
                onChange={(e) => setMonth(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <Select
                label="Ano"
                value={year}
                options={getYearOptions()}
                onChange={(e) => setYear(e.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => onOkay(year, month)}
          autoFocus
        >
          Pronto
        </Button>
      </DialogActions>
    </DialogUI>
  )
}