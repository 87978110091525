import React, { useState } from 'react'

import Header from './header'

import Dialog from '../../../components/Dialog'
import Form from '../../../components/Form'

import * as rqCliente from '../../../api/cliente'

import checkData from '../../../utils/check-form-data'
import noop from '../../../utils/noop'

export default (props) => {
  const clientId = props.match.params.id
  const isEditing = typeof clientId !== 'undefined'

  const goTo = props.history.push

  const goBack = () => {
    const sp = new URLSearchParams(props.location.search)

    switch (sp.get('from')) {
      case 'reserva': {
        sp.delete('from')
        goTo('/calendar/new/reserva?' + sp.toString())
        break
      }
      default: {
        props.history.goBack()
      }
    }
  }

  const completeRegister = () => isEditing
    ? goTo(`/more/clientes/completo/edit/${clientId}`)
    : goTo(`/more/clientes/completo/new`)

  const [ dialog, createDialog ] = useState({})
  const [ isLoading, setIsLoading ] = useState(true)
  const [ formWasFilled, setFormWasFilled ] = useState(false)

  const [ formData, setFormData ] = useState({
    clientName: '',
    telephone : '',
  })

  const nonRequiredFields = []

  const handleClose = () => {
    createDialog({
      open: false
    })
  }

  const handleChangeValue = (type) => (event) => {
    const { value } = event.target

    setFormData({
      ...formData,
      [type]: value
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const onReqSuccess = (data) => {
      createDialog({
        open: true,
        title: isEditing
          ? 'Editado'
          : 'Criado',
        message: isEditing
          ? 'O cliente foi atualizado com sucesso.'
          : 'O cliente foi criado com sucesso.',
        afterClose: goBack,
      })
    }
    const onReqError = ({ response }) => {
      let message = response && response.data && response.data.error
        ? response.data.error
        : 'Erro interno.'

      createDialog({
        open: true,
        title: 'Erro',
        message,
      })
    }
    const continueSubmit = () => {
      const reqBody = {
        nome            : formData.clientName,
        email           : null,
        id_nacionalidade: null,
        cpf             : null,
        rua             : null,
        numero          : null,
        bairro          : null,
        id_cidade       : null,
        celular         : formData.telephone,
        updated_at      : null
      }

      if (isEditing) {
        rqCliente.update(clientId, reqBody)
          .then(onReqSuccess)
          .catch(onReqError)
      } else {
        rqCliente.add(reqBody)
          .then(onReqSuccess)
          .catch(onReqError)
      }
    }
    const stopSumbit = () => {
      createDialog({
        open: true,
        title: 'Erro',
        message: 'Algum campo não foi preenchido.',
      })
    }

    checkData(formData, nonRequiredFields)
      .then(continueSubmit)
      .catch(stopSumbit)
  }

  // Obter informações do cliente
  if (isEditing && !formWasFilled) {
    rqCliente.get(clientId).then(data => {
      setFormData({
        clientName: data.nome || '',
        telephone : data.celular || '',
      })
    }).then(() => {
      setIsLoading(false)
      setFormWasFilled(true)
    })
  } else {
    if (isLoading) {
      setIsLoading(false)
    }
  }

  const formFields = [
    [
      {
        label: 'Nome completo',
        type: 'normal',
        inputType: 'text',
        value: formData.clientName,
        onChange: handleChangeValue('clientName'),
      },
    ],
    [
      {
        label: 'Celular',
        type: 'normal',
        inputType: 'text',
        value: formData.telephone,
        onChange: handleChangeValue('telephone'),
      },
    ],
  ]

  return (
    <>
      <Dialog
        open={dialog.open}
        title={dialog.title}
        message={dialog.message}
        onClose={handleClose}
        afterClose={() => (dialog.afterClose || noop)()}
      />

      <Form
        mainButtonLabel={isEditing ? 'Atualizar' : 'Salvar'}
        isLoading={isLoading}
        fields={formFields}
        onSubmit={handleSubmit}
        headerComponent={(
          <Header
            goBack={goBack}
            completeRegister={completeRegister}
          />
        )}
      />
    </>
  )
}
