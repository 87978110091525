import React from 'react'

import { styled } from '@material-ui/core/styles'

import {
  Button,
  FormControl as FormControlUI,
  Grid,
  InputAdornment,
  Paper as PaperUI,
  TextField
} from '@material-ui/core'

import FilterableSelect from './FilterableSelect'
import Loading from './Loading'
import PageWithHeader from './PageWithHeader'
import Select from './Select'

const GRID_SPACING = 2
const FIELD_MAX_COLUMNS = 12

const Root = styled('form')({
  margin: 12,
})
const FormControl = styled(FormControlUI)({
  width: '100%',
})
const Paper = styled(PaperUI)({
  padding: '12px',
})
const GridWithMarginButton = styled(Grid)({
  marginBottom: '10px',
})

function TextFieldWithPrefix({ prefix, ...props }) {
  return (
    <TextField
      {...props}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {prefix}
          </InputAdornment>
        )
      }}
    />
  )
}

function buildField({ type, inputType, ...props }) {
  switch (type) {
    case 'autocomplete':
      return (
        <FormControl>
          <FilterableSelect {...props} />
        </FormControl>
      )
    case 'normal':
      return (
        <FormControl>
          <TextField
            {...props}
            type={inputType}
            InputLabelProps={
              inputType === 'date' || inputType === 'time'
                ? { shrink: true }
                : null
            }
          />
        </FormControl>
      )
    case 'prefix':
      return (
        <FormControl>
          <TextFieldWithPrefix
            {...props}
            type={inputType}
          />
        </FormControl>
      )
    case 'select':
      return (
        <FormControl>
          <Select {...props} />
        </FormControl>
      )
    // no default
  }
}

function buildColumnFields(columnFields, cfIndex) {
  return (
    <GridWithMarginButton key={cfIndex} container spacing={GRID_SPACING}>
      {columnFields.map((field, fIndex) => (
        <Grid key={fIndex} item xs={FIELD_MAX_COLUMNS / columnFields.length}>
          {buildField(field)}
        </Grid>
      ))}
    </GridWithMarginButton>
  )
}

export default function Form({
  mainButtonLabel = '',
  secondaryButtonLabel = '',
  isLoading = true,
  fields = [],
  onSubmit = () => {},
  onSecondarySubmit = null,
  headerComponent
}) {
  if (isLoading) {
    return <Loading />
  }

  return (
    <PageWithHeader headerComponent={headerComponent}>
      <Root onSubmit={onSubmit || (event => {
        event.preventDefault()
      })}>
        <Paper square>
          {fields.map(buildColumnFields)}

          {onSubmit && (
            <Grid
              as={onSecondarySubmit ? GridWithMarginButton : Grid}
              container
              spacing={GRID_SPACING}
            >
              <Grid item xs={FIELD_MAX_COLUMNS}>
                <FormControl>
                  <Button
                    type="submit"
                    color="primary"
                    size="medium"
                    variant="contained"
                  >
                    {mainButtonLabel}
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          )}

          {onSecondarySubmit && (
            <Grid container spacing={GRID_SPACING}>
              <Grid item xs={FIELD_MAX_COLUMNS}>
                <FormControl>
                  <Button
                    type="button"
                    color="secondary"
                    size="medium"
                    variant="contained"
                    onClick={onSecondarySubmit}
                  >
                    {secondaryButtonLabel}
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          )}
        </Paper>
      </Root>
    </PageWithHeader>
  )
}