import req from './utils/req'

/**
 * View
 */
export const view = (id) => req({

  method: 'GET',
  route: `/email/view/calendario/${id}`,

})

/**
 * Send
 */
export const send = (id) => req({

  method: 'GET',
  route: `/email/send/calendario/${id}`,

})
