import React, { useState } from 'react'

import Header from './header'

import Dialog from '../../../components/Dialog'
import Form from '../../../components/Form'

import * as rqCadastros from '../../../api/cadastros'
import * as rqUsuario from '../../../api/usuario'

import * as groups from '../../../groups'

import checkData from '../../../utils/check-form-data'
import noop from '../../../utils/noop'

export default (props) => {
  const goBack = () => props.history.push('/more')
  
  const [ dialog, createDialog ] = useState({})
  const [ isLoading, setIsLoading ] = useState(true)

  const [ formData, setFormData ] = useState({
    'comissao'  : 0,
    'taxa'      : 0,
    'status_dia': 0
  })

  const nonRequiredFields = []

  rqUsuario.get().then(data => {
    if (!data.groups.includes(groups.PROPRIETARIO) && !data.groups.includes(groups.ADMIN)) {
      props.history.push('/more')
    }
  })

  const handleClose = () => {
    createDialog({
      open: false
    })
  }

  const handleChangeValue = (type) => (event) => {
    const { value } = event.target

    setFormData({
      ...formData,
      [type]: value
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const onReqSuccess = (data) => {
      createDialog({
        open: true,
        title: 'Editado',
        message: 'O valores padrão foram editados com sucesso.',
        afterClose: goBack,
      })
    }
    const onReqError = ({ response }) => {
      let message = response && response.data && response.data.error
        ? response.data.error
        : 'Erro interno.'

      createDialog({
        open: true,
        title: 'Erro',
        message,
      })
    }
    const continueSubmit = () => {
      rqCadastros.updateValores(formData)
        .then(onReqSuccess)
        .catch(onReqError)
    }
    const stopSumbit = () => {
      createDialog({
        open: true,
        title: 'Erro',
        message: 'Algum campo não foi preenchido.',
      })
    }

    checkData(formData, nonRequiredFields)
      .then(continueSubmit)
      .catch(stopSumbit)
  }

  // Obter lista dos valores padrão
  if (isLoading) {
    rqCadastros.listValores().then(data => {
      const getValue = prop =>
        Number(data.find(({ property }) => property === prop).value)

      setFormData({
        comissao  : getValue('comissao'),
        taxa      : getValue('taxa'),
        status_dia: getValue('status_dia'),
      })

      setIsLoading(false)
    })
  }

  const formFields = [
    [
      {
        label: 'Comissão padrão',
        type: 'prefix',
        prefix: "%",
        inputType: 'number',
        value: formData['comissao'],
        onChange: handleChangeValue('comissao'),
      },
    ],
    [
      {
        label: 'Taxa padrão',
        type: 'prefix',
        prefix: "R$",
        inputType: 'number',
        value: formData['taxa'],
        onChange: handleChangeValue('taxa'),
      },
    ],
    [
      {
        label: 'Núm. dias Status Atraso',
        type: 'normal',
        inputType: 'number' ,
        value: formData['status_dia'],
        onChange: handleChangeValue('status_dia')
      },
    ],
  ]

  return (
    <>
      <Dialog
        open={dialog.open}
        title={dialog.title}
        message={dialog.message}
        onClose={handleClose}
        afterClose={() => (dialog.afterClose || noop)()}
      />

      <Form
        mainButtonLabel="Salvar edição"
        isLoading={isLoading}
        fields={formFields}
        onSubmit={handleSubmit}
        headerComponent={<Header goBack={goBack} />}
      />
    </>
  )
}