/**
 * Juntar data e hora (YYYY-MM-DD HH:mm:SS)
 * @exmaple
 * createTimestamp('2001-01-01', '00:00')
 * // => '2001-01-01 00:00:00'
 * createTimestamp('2001-01-01', '00:00:00')
 * // => '2001-01-01 00:00:00'
 */
export default function createTimestamp(date, time) {
  return `${date} ${time.padEnd(8, ':00')}`
}