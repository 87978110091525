import React, { useState } from 'react'

import ApartmentIcon from '@material-ui/icons/Apartment'

import * as rqApartamento from '../../../api/apartamento'

import Header from './header'
import List from '../../../components/List'
import Loading from '../../../components/Loading'
import PageWithHeader from '../../../components/PageWithHeader'

export default (props) => {
  const goTo = props.history.push
  const goBack = () => goTo('/more')

  const [ list, setList ] = useState()

  if (!list) {
    rqApartamento.list().then((data) => {
      if (!data) setList([])
      else {
        setList([
          {
            content: data.map(({ id, nome }) => ({
              label: nome,
              onClick: () => goTo(`/more/apartamentos/edit/${id}`),
              Icon: ApartmentIcon
            }))
          }
        ])
      }
    })

    return <Loading />
  }

  return (
    <PageWithHeader headerComponent={<Header goBack={goBack} />}>
      <List menuItems={list} />
    </PageWithHeader>
  )
}