import React, { useState } from 'react'

import { styled } from '@material-ui/core/styles'

import { Typography } from '@material-ui/core'

import Dialog from '../../components/Dialog'
import Form from '../../components/Form'

import * as rqUsuario from '../../api/usuario'

import checkData from '../../utils/check-form-data'
import noop from '../../utils/noop'

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
})
const Main = styled('main')({
  position: 'relative',
  flex: 1,
})
const MainContent = styled('div')({
  position: 'absolute',
  width: '100%',
  top: '50%',
  transform: 'translateY(-50%)',
})
const Header = styled('div')({
  textAlign: 'center',
  marginBottom: 10,
})
const Logo = styled('img')({
  width: '65%',
  marginBottom: 5,
})
const Footer = styled('footer')({
  padding: '15px 0', 
  textAlign: 'center',
  backgroundColor: '#eee',
})

export default props => {
  const [ dialog, createDialog ] = useState({})

  const [ formData, setFormData ] = useState({
    login   : '',
    password: '',
  })

  const nonRequiredFields = []

  const handleClose = () => {
    createDialog({
      open: false
    })
  }

  const handleChangeValue = (type) => (event) => {
    const { value } = event.target

    setFormData({
      ...formData,
      [type]: value
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const onReqSuccess = ({ access_token }) => {

      // Registra o token no browser
      window.localStorage.setItem("jwt_token", access_token)

      props.history.push('/')
    }
    const onReqError = (data) => {
      console.error(data)

      let message = data.response && data.response.data && data.response.data.error
        ? data.response.data.error
        : 'Erro interno.'

      createDialog({
        open: true,
        title: 'Erro',
        message,
      })
    }
    const continueSubmit = () => {
      rqUsuario.auth(formData.login, formData.password)
        .then(onReqSuccess)
        .catch(onReqError)
    }
    const stopSumbit = () => {
      createDialog({
        open: true,
        title: 'Erro',
        message: 'Algum campo não foi preenchido.',
      })
    }

    checkData(formData, nonRequiredFields)
      .then(continueSubmit)
      .catch(stopSumbit)
  }

  const formFields = [
    [
      {
        label: 'Seu login',
        type: 'normal',
        inputType: 'text',
        value: formData.login,
        onChange: handleChangeValue('login'),
      },
    ],
    [
      {
        label: 'Sua senha',
        type: 'normal',
        inputType: 'password',
        value: formData.password,
        onChange: handleChangeValue('password'),
      }
    ]
  ]

  return (
    <>
      <Dialog
        open={dialog.open}
        title={dialog.title}
        message={dialog.message}
        onClose={handleClose}
        afterClose={() => (dialog.afterClose || noop)()}
      />

      <Container>
        <Main>
          <MainContent>
            <Header>
              <Logo src="/logo.png" />
            </Header>

            <Form
              mainButtonLabel="Entrar"
              isLoading={false}
              fields={formFields}
              onSubmit={handleSubmit}
            />
          </MainContent>
        </Main>

        <Footer className="hide-with-keyboard">
          <Typography variant="subtitle2" color="textSecondary">
            Copyright &copy; {(new Date()).getFullYear()} Kimera Tecnologia
          </Typography>
        </Footer>
      </Container>
    </>
  )
}