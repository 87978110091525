import req from './utils/req'

/**
 * Filter cidade
 */
export const filterCidade = (limit = 10, name) => req({

  method: 'GET',
  route: `/buscar/cidade?limit=${limit}&nome=${name}`,

})

/**
 * Get cidade
 */
export const getCidade = (id) => req({

  method: 'GET',
  route: `/cidade/${id}`,

})

/**
 * List nacionalidade
 */
export const listNacionalidade = () => req({

  method: 'GET',
  route: `/nacionalidade`,

})

/**
 * List status
 */
export const listStatus = () => req({

  method: 'GET',
  route: `/statusagenda`,

})

/**
 * List uf
 */
export const listUf = () => req({

  method: 'GET',
  route: `/uf`,

})

/**
 * List valores
 */
export const listValores = () => req({

  method: 'GET',
  route: `/valorespadrao`,

})

/**
 * List valores
 */
export const updateValores = (data) => req({

  method: 'PUT',
  route: `/valorespadrao/update`,
  data

})
