import React, { useState } from 'react'

// Material-UI components
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

// Material-UI utils
import { makeStyles } from '@material-ui/core/styles'

// Utils
import checkDate from '../../../utils/check-date'
import cn from '../../../utils/cn'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1.5),
    marginBottom: 10
  },

  // Text styles
  centerText: { textAlign: 'center' },
  boldText: { fontWeight: 'bold' },

  // Definição para célula do dia
  dayContainer: {
    padding: '3px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 1,
    boxShadow: '0 0 1px 0 #ddd',
  },
  dayContainerActive: {
    backgroundColor: 'rgba(255, 235, 59, .35)'
  },

  // Estilos referentes ao status (tag) do dia
  statusContainer: {
    marginTop: -10,
    padding: 0,
    height: 18,
    textAlign: 'center',
  },
  status: {
    margin: '0 1.5px',
    display: 'inline-block',
    width: 4,
    height: 4,
    borderRadius: '100%',
  },
}))

function getNumberOfDays(year, month) {
  return ({
    '1': 31,
    '2': year % 4 === 0 ? 29 : 28,
    '3': 31,
    '4': 30,
    '5': 31,
    '6': 30,
    '7': 31,
    '8': 31,
    '9': 30,
    '10': 31,
    '11': 30,
    '12': 31
  })[month]
}

/**
 * Obter "matriz" com dias do mês informado
 *
 * @params {number} year Ano do calendário
 * @params {number} month Mês do calendário
 * @return {Array} Matriz com os dias para o calendário
 * @examples
 * getCalendarArray(1999, 9)
 * //=> [
 *   [29, 30, 31, 1, 2, 3, 4]
 *   [5, 6, 7, 8, 9, 10, 11]
 *   [12, 13, 14, 15, 16, 17, 18]
 *   [19, 20, 21, 22, 23, 24, 25]
 *   [26, 27, 28, 29, 30, 1, 2]
 * ]
 */
function getCalendarArray(year, month) {
  const CALENDAR_ROW = 6
  const CALENDAR_COL = 7

  const allDays = []
  const calendar = []
  
  // Dia da semana do primeiro dia do mês (ex.: quarta = 3)
  const firstMonthDate = (new Date(`${year}/${month}/1 00:00:00`)).getDay()

  // Último dia do mês passado
  const lastMonthDay = getNumberOfDays(year, month === 1 ? 12 : month - 1)

  // Quantidade de dias nesse mês
  const numberOfDays = getNumberOfDays(year, month)

  for (let i = 0; i < CALENDAR_ROW * CALENDAR_COL; i++) {
    if (allDays.length < firstMonthDate) {
      allDays.push(lastMonthDay - firstMonthDate + 1 + i)
    } else {

      if (i + 1 - firstMonthDate > numberOfDays) {
        allDays.push(i - numberOfDays - firstMonthDate + 1)
      } else {
        allDays.push(i + 1 - firstMonthDate)
      }
    }
  }
  
  for (let i = 0; i < CALENDAR_ROW; i++) {
    var col = []
    for (let j = 0; j < CALENDAR_COL; j++) {
      col.push(allDays[i * CALENDAR_COL + j])
    }
    
    calendar.push(col)
  }

  return calendar
}

/**
 * Obter as cores dos status que correspondem a determinado dia
 *
 * @example
 * getStatus('2019-01-05', [
 *   { start_date: '2019-01-01', end_date: '2019-01-10', color: 'red' },
 *   { start_date: '2019-01-15', end_date: '2019-01-20', color: 'blue' }
 * ])
 * //=> [ 'red' ]
 */
const getStatusColors = (date, data) => {
  const allColors = data
    .filter(({ start_date, end_date }) => checkDate(
      date,
      start_date.split(' ')[0],
      end_date.split(' ')[0]
    ))
    .map(({ color }) => color)


  // Remover itens (colors) repetidos
  return allColors.filter((colors, index) => allColors.indexOf(colors) === index)
}

/**
 * Obter dia, mês e ano atual
 *
 * @example
 * getCurrentDate() // Supondo que hoje seja 14 de setembro de 1999
 * //=> {
 *   year: 1999,
 *   month: 09,
 *   day: 14
 * }
 */
const getCurrentDate = () => {
  const date = new Date()

  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  }
}

const LabelDays = () => {
  const classes = useStyles()
  const WEEK_DAY_NAMES = [ 'D', 'S', 'T', 'Q', 'Q', 'S', 'S' ]

  return (
    <Grid
      container
      style={{
        marginBottom: 10
      }}
    >
      {WEEK_DAY_NAMES.map((dayName, index) => (

        // Onde "index" é referente a uma constante
        <Grid item xs key={index} className={classes.centerText}>
          <Typography className={classes.boldText}>
            {dayName}
          </Typography>
        </Grid>
      ))}
    </Grid>
  )
}

const DayContainer = ({
  colors = [],
  isActive = false,
  isOfOtherMonth = false,
  isToday,
  onClick,
  value,
}) => {
  const classes = useStyles()

  colors = !isOfOtherMonth ? colors : []
  colors = colors.slice(0, 3)

  // Configurações de qual ser a cor do texto do dia
  let dayTextColor

  const COLOR_GRAY    = '#aaa'
  const COLOR_PRIMARY = '#16a085'
  const COLOR_BLACK   = '#000'

  if (isOfOtherMonth) {
    dayTextColor = COLOR_GRAY
  } else {
    if (isToday) {
      dayTextColor = COLOR_PRIMARY
    } else {
      dayTextColor = COLOR_BLACK
    }
  }

  return (
    <Grid
      item
      xs
      onClick={onClick}
      className={cn([
        classes.dayContainer,
        !isOfOtherMonth && isActive ? classes.dayContainerActive : null
      ])}
    >
      <Typography style={{ color: dayTextColor }}>
        {value}
      </Typography>

      <div className={classes.statusContainer}>
        {colors.map((color, index) => (
          <div
            key={index}
            className={classes.status}
            style={{ backgroundColor: color }}
          />
        ))}
      </div>
    </Grid>
  )
}

const DaysList = ({
  calendarData,
  date,
  onChange,
}) => {
  const [ activedDay, selectDay ] = useState(false)

  const {
    day  : currentDay,
    month: currentMonth,
    year : currentYear
  } = getCurrentDate()

  const calendar = getCalendarArray(parseInt(date.year), parseInt(date.month))

  const updateDay = (day) => {
    selectDay(day)
    onChange(day)
  }

  return calendar.map((rowsDays, index) => {
    const ROW_KEY = 'row' + index

    const isFirstRow = index === 0
    const isLastsRow = index + 1 === calendar.length
                    || index + 2 === calendar.length 

    return (
      <Grid
        container
        key={ROW_KEY}
        style={{
          marginBottom: 0
        }}
      >
        {rowsDays.map((colDay, index) => {
          const COL_KEY = 'col-' + index

          // Verificar se é de outro mês
          const isOfPastMonth = isFirstRow && colDay >= 15
          const isOfNextMonth = isLastsRow && colDay <= 15
          const isOfOtherMonth = isOfPastMonth || isOfNextMonth

          const statusColors = getStatusColors(
            `${date.year}-${date.month}-${colDay}`,
            calendarData
          )

          return (
            <DayContainer
              key={COL_KEY}
              colors={statusColors}
              isActive={activedDay === colDay}
              isOfOtherMonth={isOfOtherMonth}
              isToday={
                   date.year  === currentYear
                && date.month === currentMonth
                && colDay     === currentDay
              }
              onClick={() => !isOfOtherMonth && updateDay(colDay)}
              value={colDay}
            />
          )
        })}
      </Grid>
    )
  })
}

export default ({
  calendarData = [],
  date = {},
  onChange,
  onUpdateMonth
}) => {
  const classes = useStyles()
  const currentDate = getCurrentDate()

  const dateYear = date.year || currentDate.year
  const dateMonth = date.month || currentDate.month 

  const onChangeMonth = (type) => {
    var year
    var month

    switch (type) {
      case 'prev':
        year = dateMonth === 1 ? dateYear - 1 : dateYear
        month = dateMonth === 1 ? 12 : dateMonth - 1
        break
      case 'next':
        year = dateMonth === 12 ? dateYear + 1 : dateYear
        month = dateMonth === 12 ? 1 : dateMonth + 1 
        break

      // no default
    }

    onUpdateMonth(year, Number(month))
  }

  const onChangeDay = (day) => onChange({
    year: dateYear,
    month: dateMonth,
    day,
  })

  return (
    <Paper square>
      <ButtonGroup
        style={{
          padding: 5,
          paddingBottom: 0,
        }}
        size="small"
        variant="outlined"
        color="primary"
        fullWidth
        aria-label="contained primary button group"
      >
        <Button onClick={() => onChangeMonth('prev')}>
          <ArrowBackIcon />
        </Button>
        <Button onClick={() => onChangeMonth('next')}>
          <ArrowForwardIcon />
        </Button>
      </ButtonGroup>

      <div className={classes.container}>
        <LabelDays />

        <DaysList
          calendarData={calendarData}
          date={{ year: dateYear, month: dateMonth }}
          onChange={onChangeDay}
        />
      </div>
    </Paper>
  )
}