import { API_URL, getJwtToken } from './config'

/**
 * Criar as opções (options) para o Axios
 */
export default function createOptions({ method, route, data=null }) {
  return {
    method,
    url: `${API_URL}${route}`,
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    },
    data
  }
}
