import React, { useState } from 'react'

import Header from './header'

import Dialog from '../../components/Dialog'
import Form from '../../components/Form'

import * as rqUsuario from '../../api/usuario'

import noop from '../../utils/noop'

export default (props) => { 
  const [ dialog, createDialog ] = useState({})
  const [ isLoading, setIsLoading ] = useState(true)
  const [ formData, setFormData ] = useState({})

  const handleClose = () => {
    createDialog({
      open: false
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    window.localStorage.removeItem('jwt_token')

    createDialog({
      open: true,
      title: 'Encerrado',
      message: 'Você foi desconectado.',
      afterClose: () => {
        props.history.push('/login')
      }
    })
  }

  if (isLoading) {
    rqUsuario.get().then(data => {
      setFormData({
        name: data['name'],
        login: data['login'],
        email: data['email']
      })
    }).then(() => {
      setIsLoading(false)
    })
  }

  const formFields = [
    [
      {
        label: 'Seu nome',
        type: 'normal',
        inputType: 'text',
        value: formData.name,
        disabled: true,
      },
    ],
    [
      {
        label: 'Seu login',
        type: 'normal',
        inputType: 'text',
        value: formData.login,
        disabled: true,
      }
    ],
    [
      {
        label: 'Seu e-mail',
        type: 'normal',
        inputType: 'text',
        value: formData.email,
        disabled: true,
      }
    ]
  ]

  return (
    <>
      <Dialog
        open={dialog.open}
        title={dialog.title}
        message={dialog.message}
        onClose={handleClose}
        afterClose={() => (dialog.afterClose || noop)()}
      />

      <Form
        mainButtonLabel="Fazer logoff"
        isLoading={isLoading}
        fields={formFields}
        onSubmit={handleSubmit}
        headerComponent={<Header />}
      />
    </>
  )
}