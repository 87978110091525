import React, { useState } from 'react'

import { styled } from '@material-ui/core/styles'

import * as rqCalendario from '../../../api/calendario'

import Header from './header'

import PageWithHeader from '../../../components/PageWithHeader'
import ListAgendas from '../../../components/list-agendas'
import Loading from '../../../components/Loading'

const Container = styled('div')({
  margin: 12
})

export default (props) => {
  const search = props.location.search
  const goBack = () => props.history.push(`/calendar/search${search}`)

  const [ searchList, setSearchList ] = useState(false)

  if (!searchList) {
    rqCalendario.search(search).then(data => {
      setSearchList(data)
    })

    return <Loading />
  }

  return (
    <PageWithHeader headerComponent={<Header goBack={goBack} />}>
      <Container>
        <ListAgendas
          list={searchList}
          goTo={props.history.push}
        />
      </Container>
    </PageWithHeader>
  )
}