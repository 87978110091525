import axios from 'axios'

import createOptions from './create-options'

/**
 * Fazer uma requisição e retornar somente os dados (data)
 */
export default function req(options) {
  return axios(createOptions(options))
    .then(({ data }) => data)
}