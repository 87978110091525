import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Fab from '@material-ui/core/Fab'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

const useStyles = makeStyles(theme => ({
  shadow: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.75)',
    zIndex: 1100,
  },
  container: {
    position: 'fixed',
    padding: props => props.position === 'bottom'
      ? theme.spacing(5, 1.75, 3, 1.75)
      : theme.spacing(3, 1.75, 5, 1.75),
    right: 0,
    left: 0,
    backgroundColor: 'white',
    transition: '100ms linear',
    boxShadow: '0px -1px 3px 0px rgba(0, 0, 0, .2),'
             + '0px -1px 1px 0px rgba(0, 0, 0, .14),'
             + '0px -2px 1px -1px rgba(0, 0, 0, .12);',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    zIndex: 9999,
  },
  'closeButton': {
    position: 'absolute',
    left: '50%',
  },
}))

const Shadow = ({ isOpen }) => {
  const classes = useStyles()

  return isOpen
    ? <div className={classes.shadow} />
    : null
}

export default ({ children, isOpen, onClose, position='bottom' }) => {
  const classes = useStyles({ position })
  const hiddenPos = (position === 'bottom' ? 1 : -1) * 150

  position = position === 'bottom' || position === 'top'
    ? position
    : 'bottom'

  return (
    <>
      <Shadow isOpen={isOpen} />

      <div
        className={classes.container}
        style={{
          transform: `translateY(${isOpen ? 0 : hiddenPos}%)`,
          [position]: 0,
        }}
      >
        {position === 'top' && (
          <Fab
            color="secondary"
            className={classes.closeButton}
            onClick={onClose}
            style={{
              bottom: 0,
              transform: 'translate(-50%, 50%)',
            }}
          >
            <ExpandLessIcon />
          </Fab>
        )}
        {position === 'bottom' && (
          <Fab
            color="secondary"
            className={classes.closeButton}
            onClick={onClose}
            style={{
              top: 0,
              transform: 'translate(-50%, -50%)',
            }}
          >
            <ExpandMoreIcon />
          </Fab>
        )}

        {children}
      </div>
    </>
  )
}