import React, { useState } from 'react'
import { isValid as cpfIsValid } from 'cpf'

import Header from './header'

import Dialog from '../../../components/Dialog'
import Form from '../../../components/Form'

import * as rqCadastros from '../../../api/cadastros'
import * as rqCliente from '../../../api/cliente'

import checkData from '../../../utils/check-form-data'
import noop from '../../../utils/noop'

export default (props) => {
  const goBack = () => props.history.goBack()

  const clientId = props.match.params.id
  const isEditing = typeof clientId !== 'undefined'

  const [ nationalityList, setNationalityList ] = useState([])
  const [ cityList, setCityList ] = useState([])

  const [ dialog, createDialog ] = useState({})
  const [ isLoading, setIsLoading ] = useState(true)
  const [ formWasFilled, setFormWasFilled ] = useState(false)

  const searchParams = new URLSearchParams(props.location.search)

  const [ formData, setFormData ] = useState({
    clientName   : '',
    nationalityId: null,
    telephone    : '',
    cpf          : '',
    email        : '',
    street       : '',
    streetNumber : '',
    cityId       : null,
    neighborhood : '',
  })

  const nonRequiredFields = []

  const setNationalityId = (nationalityId) => {
    setFormData({ ...formData, nationalityId })
  }
  const setCityId = (cityId) => {
    setFormData({ ...formData, cityId })
  }

  const handleClose = () => {
    createDialog({
      open: false
    })
  }

  const handleChangeValue = (type) => (event) => {
    const { value } = event.target

    setFormData({
      ...formData,
      [type]: value
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const onReqSuccess = (data) => {
      createDialog({
        open: true,
        title: 'Editado',

        message: isEditing
          ? 'O cliente foi atualizado com sucesso.'
          : 'O cliente foi criado com sucesso.',

        afterClose: searchParams.has('agendamento')
          ? props.history.push(`/calendar/edit/agendamento/${searchParams.get('agendamento')}`)
          : goBack
      })
    }
    const onReqError = ({ response }) => {
      let message = response && response.data && response.data.error
        ? response.data.error
        : 'Erro interno.'

      createDialog({
        open: true,
        title: 'Erro',
        message,
      })
    }
    const continueSubmit = () => {
      if (!cpfIsValid(formData.cpf)) {
        createDialog({
          open: true,
          title: 'Erro',
          message: 'Esse CPF não é válido.',
        })
      } else {
        const reqBody = {
          nome: formData.clientName,
          email: formData.email,
          id_nacionalidade: formData.nationalityId,
          cpf: formData.cpf,
          rua: formData.street,
          numero: formData.streetNumber,
          bairro: formData.neighborhood,
          id_cidade: formData.cityId,
          celular: formData.telephone,
          updated_at: null
        }

        if (isEditing) {
          rqCliente.update(clientId, reqBody)
            .then(onReqSuccess)
            .catch(onReqError)
        } else {
          rqCliente.add(reqBody)
            .then(onReqSuccess)
            .catch(onReqError)
        }
      }
    }
    const stopSumbit = () => {
      createDialog({
        open: true,
        title: 'Erro',
        message: 'Algum campo não foi preenchido.',
      })
    }

    checkData(formData, nonRequiredFields)
      .then(continueSubmit)
      .catch(stopSumbit)
  }

  const onTypeCityName = (event) => {
    const MAX_LENGTH = 5
    const value = event.target.value

    if (value.length >= MAX_LENGTH) {
      rqCadastros.filterCidade(5, value).then((data) => {
        const list = data.map(item => ({
          label: `${item.nome}/${item.uf}`,
          value: item.id
        }))
        if (list.length > 0) {
          setCityList(list)
        }
      })
    }
  }

  // Obter lista de nacionalidades
  if (nationalityList.length === 0) {
    rqCadastros.listNacionalidade().then(data => {
      const list = data.map(item => ({ label: item.nome, value: item.id}))
      setNationalityList(list)
    })
  }

  // Obter informações do cliente
  if (
       isEditing // Se tiver editando
    && !formWasFilled
    && nationalityList.length > 0
  ) {
    rqCliente.get(clientId).then(data => {
      setFormData({
        clientName   : data.nome || '',
        nationalityId: data.id_nacionalidade,
        telephone    : data.celular || '',
        cpf          : data.cpf || '',
        email        : data.email || '',
        street       : data.rua || '',
        streetNumber : data.numero || '',
        cityId       : data.id_cidade,
        neighborhood : data.bairro || '',
      })
      return data.id_cidade
    }).then(cityId => {
      if (cityId) {
        rqCadastros.getCidade(cityId).then(item => {
          setCityList([{
            label: `${item.nome}/${item.uf}`,
            value: item.id
          }])
        }).then(() => {
          setFormWasFilled(true)
        })
      } else {
        setFormWasFilled(true)
      }
    })
  }

  // Se está carregando
  if (isLoading) {

    // Se alguns itens ainda não foram carregados
    if (
         nationalityList.length > 0
      && (isEditing ? formWasFilled : true)
    ) {
      setIsLoading(false)
    }
  }

  const formFields = [
    [
      {
        label: 'Nome completo',
        type: 'normal',
        inputType: 'text',
        value: formData.clientName,
        onChange: handleChangeValue('clientName'),
      },
    ],
    [
      {
        placeholder: 'Nacionalidade',
        type: 'autocomplete',
        suggestions: nationalityList,
        defaultValue: nationalityList.find(({ value }) => value === formData.nationalityId),
        onChange: ({ value: id }) => setNationalityId(id),
      },
    ],
    [
      {
        label: 'Celular',
        type: 'normal',
        inputType: 'text',
        value: formData.telephone,
        onChange: handleChangeValue('telephone'),
      },
      {
        label: 'CPF',
        type: 'normal',
        inputType: 'text',
        value: formData.cpf,
        onChange: handleChangeValue('cpf'),
      },
    ],
    [
      {
        label: 'E-mail',
        type: 'normal',
        inputType: 'email',
        value: formData.email,
        onChange: handleChangeValue('email'),
      },
    ],
    [
      {
        label: 'Rua',
        type: 'normal',
        inputType: 'text',
        value: formData.street,
        onChange: handleChangeValue('street'),
      },
      {
        label: 'Número',
        type: 'normal',
        inputType: 'number',
        value: formData.streetNumber,
        onChange: handleChangeValue('streetNumber'),
      }
    ],
    [
      {
        placeholder: 'Cidade',
        type: 'autocomplete',
        suggestions: cityList,
        defaultValue: cityList.find(({ value }) => value === formData.cityId),
        onChange: ({ value: id }) =>  setCityId(id),
        onKeyUp: onTypeCityName
      },
    ],
    [
      {
        label: 'Bairro',
        type: 'normal',
        inputType: 'text',
        value: formData.neighborhood,
        onChange: handleChangeValue('neighborhood'),
      },
    ],
  ]

  return (
    <>
      <Dialog
        open={dialog.open}
        title={dialog.title}
        message={dialog.message}
        onClose={handleClose}
        afterClose={() => (dialog.afterClose || noop)()}
      />

      <Form
        mainButtonLabel={isEditing ? 'Atualizar' : 'Salvar'}
        isLoading={isLoading}
        fields={formFields}
        onSubmit={handleSubmit}
        headerComponent={
          <Header goBack={
            searchParams.get('agendamento')
              ? () => props.history.push('/')
              : goBack
          } />
        }
      />
    </>
  )
}
